import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';

import { LoaderCell } from '../../CellRenderingHelpers/LoaderCell';
import { TRowActions } from '../../sharedTypes';
import ActionMenuCellRenderer from '../components/ActionMenuCellRenderer';

/**
 * used to add standardized properties and additional columns to the column definitions.
 * */
export const modifyColumnDefs = <TData extends object>({
  columnDefs,
  rowActions
}: {
  rowActions?: TRowActions<TData>;
  columnDefs?: (ColDef<TData, any> | ColGroupDef<TData>)[] | null;
}) => {
  if (!columnDefs) {
    return [];
  }

  const lastItem = columnDefs.length - 1;
  const modifiedDefinitions: (ColDef<TData, any> | ColGroupDef<TData>)[] | null = columnDefs.map((columnDef, index) => {
    // @ts-ignore
    if (!columnDef || columnDef?.children) {
      return columnDef;
    }
    // @ts-ignore
    const originalRenderer = columnDef?.cellRenderer
      ? // @ts-ignore
        columnDef?.cellRenderer
      : ({ valueFormatted, value }: ICellRendererParams) => valueFormatted || value || '';

    const loaderRenderer = (params: ICellRendererParams) => {
      const { value } = params;
      return !value && value !== '' ? LoaderCell(params) : originalRenderer(params);
    };

    // TEMP: while sorting and filtering on the server side aren't implemented, stopping this from being available
    const withLoaderDefs: ColDef<TData> = { ...columnDef, cellRenderer: loaderRenderer, sortable: false };

    // ensures last item spans the available space
    return lastItem === index ? { ...withLoaderDefs, flex: 1 } : withLoaderDefs;
  });

  if (!rowActions) {
    return modifiedDefinitions;
  }

  // three dot menu
  const actionMenu: ColDef = {
    headerName: '',
    resizable: false,
    rowDrag: false,
    pinned: 'right',
    width: 60,
    cellRenderer: ActionMenuCellRenderer<TData>(rowActions),
    sortable: false
  };

  return [...modifiedDefinitions, actionMenu];
};
