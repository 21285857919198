export default async function storeAgentStatus({ status, fetch_, config }) {
  if (!config || !Object.keys(config).length || !fetch_) {
    return;
  }

  try {
    await fetch_(`${config.AGENT_SERVICE_URL}/agent/status`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ status })
    });
  } catch (e) {
    console.error('Error saving agent status', e);
  }
}
