import Loader from '../../../../../Loader';
import { DIRECTORY_TAB } from '../../../constants/directoryTabs';
import SearchPlaceholder from '../../SearchPlaceholder';
import TabsPlaceholder from '../../TabsPlaceholder';

import styles from './contact-list.module.scss';

const DirectoryPlaceHolder = ({
  loading,
  numberOfContacts,
  onClearSearchTerm,
  selectedTab,
  isAdmin,
  onCreateOrUpdateClick,
  searchTerm
}: {
  loading: boolean;
  numberOfContacts: number;
  selectedTab: DIRECTORY_TAB;
  isAdmin: any;
  onCreateOrUpdateClick?: () => void;
  onClearSearchTerm?: () => void;
  searchTerm?: string;
}) => {
  const searching = Boolean(searchTerm);

  return (
    <>
      {!loading && !numberOfContacts && searching && (
        <div role="tabpanel" className="full-height">
          <SearchPlaceholder searchTerm={searchTerm} onClick={onClearSearchTerm} />
        </div>
      )}
      {!loading && !numberOfContacts && !searching && (
        <div role="tabpanel" className="full-height">
          <TabsPlaceholder type={selectedTab} isAdmin={isAdmin} createContact={onCreateOrUpdateClick} />
        </div>
      )}
      {loading && !numberOfContacts && (
        <div role="tabpanel" className="full-height flex">
          <Loader className={styles['contact-list__loader']} relative minimised small />
        </div>
      )}
    </>
  );
};

export default DirectoryPlaceHolder;
