import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import ContactTypes from 'lib/common/constants/contactTypes';

import getUserName from 'lib/common/utils/getUserName';

function getDefaultStartDate() {
  // Set the date to 3 days ago at midnight
  const threeDaysAgo = DateTime.now().minus({ days: 3 }).startOf('day');

  return threeDaysAgo.toISO();
}

export default function useInteractionHistory() {
  const [history, setHistory] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(DateTime.now().toISO());
  const [error, setError] = useState(false);

  const { config } = useConfigContext();
  const { fetch_, user } = useAuthContext();

  useEffect(() => {
    loadHistory();
  }, [startDate, endDate, user]);

  const loadHistory = async () => {
    if (!user) {
      return;
    }

    setError(false);
    setLoaded(false);

    const start = `start=${DateTime.fromISO(startDate).setZone('utc').toISO()}`;
    const end = `end=${DateTime.fromISO(endDate).setZone('utc').toISO()}`;
    const join = start && end ? '&' : '';

    const apiEndpoint = `${config.CALL_SERVICE_URL}/callhistory/${user.id}/?${start}${join}${end}`;

    try {
      const res = await fetch_(apiEndpoint);
      const data = await res.json();

      setHistory(
        data.items.map((user) => {
          return {
            talkedTo: getUserName({ ...user, fallback: 'Customer' }),
            phoneNumber: user.phoneNumber,
            type: user.phoneNumber ? ContactTypes.CALL : ContactTypes.CHAT,
            direction: user.connectionType,
            timestamp: user.calledAt,
            contactId: user.contactId
          };
        })
      );
    } catch (error) {
      logger.error(LogEvents.INTERACTION_HISTORY_LOAD_FAILED, { error });

      setError(true);
    } finally {
      setLoaded(true);
    }
  };

  const onDateChange = (date) => {
    if (!date?.start?.getTime() || !date?.end?.getTime()) {
      return;
    }

    const startDate = DateTime.fromJSDate(date.start);
    const endDate = DateTime.fromJSDate(date.end);

    setStartDate(startDate.startOf('day').toISO());
    setEndDate(endDate.endOf('day').toISO());
  };

  return {
    startDate,
    endDate,
    onDateChange,
    error,
    historyData: history,
    loaded
  };
}
