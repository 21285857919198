import { useEffect, useState } from 'react';

import cx from 'classnames';

import { TContact } from '@cloud-wave/neon-common-lib';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useOverlayContext } from 'lib/common/contexts/OverlayContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import usePaginatedLoad, { TContactListData } from 'lib/common/components/molecules/Directory/hooks/usePaginatedLoad';
import useInfiniteScroll from 'lib/common/hooks/useInfiniteScroll';

import Loader from 'lib/common/components/Loader';
import { LogEvents, logger } from 'lib/common/components/LoggerController';

import requestErrorToast from 'lib/common/utils/toast/requestErrorToast';

import { DIRECTORY_TAB } from '../../../constants/directoryTabs';
import Contact from '../../Contact/Contact';
import { TContactListProps } from '../types';
import DirectoryPlaceHolder from './DirectoryPlaceHolder';
import { formatName } from './utils';

import styles from './contact-list.module.scss';

const DirectoryContactList = ({
  searchTerm,
  selectedTab,
  isAdmin,
  isTransfer,
  onCreateOrUpdateClick,
  onClearSearchTerm,
  transferringToId,
  transferDisabled,
  onDeleteContact,
  onTransfer
}: TContactListProps) => {
  const [loading, setLoading] = useState(true);

  const {
    actions: { makeOutboundCall }
  } = useContactContext();
  const { isSoftphone } = useLayout();

  const { closeOverlay } = useOverlayContext();

  const { rootRef, sentryElement, updateTrigger } = useInfiniteScroll({ rootMargin: 10 });

  const filterLimit = searchTerm.length > 2 && selectedTab === DIRECTORY_TAB.ORGANISATION ? 500 : 100;

  const errorMessage = `Something went wrong and we couldn't fetch your contacts. Please try again.`;

  const {
    data: contacts,
    loadNext: loadContacts,
    exhausted
  } = usePaginatedLoad({
    search: searchTerm,
    type: selectedTab,
    pageSize: filterLimit
  });

  useEffect(() => {
    if (!updateTrigger) {
      return;
    }

    (async () => {
      await loadData();
    })();
  }, [updateTrigger]);

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, [selectedTab, searchTerm]);

  const loadData = async () => {
    setLoading(true);

    try {
      const loadedContacts = await loadContacts();

      logger.info(LogEvents.CONTACT.LOAD.SUCCESS, {
        searchTerm,
        selectedTab,
        numberReturned: loadedContacts?.length
      });
      setLoading(false);
    } catch (e: any) {
      requestErrorToast({ errorMessage, errorReference: e.requestId });

      logger.error(LogEvents.CONTACT.LOAD.FAIL, { error: e, searchTerm, selectedTab });

      setLoading(false);
    }
  };

  const onCallClick = async (row: TContact) => {
    if (!('phoneNumber' in row)) {
      return;
    }

    await makeOutboundCall(row.phoneNumber);
    closeOverlay();
  };

  const generateContactElements = (contact: TContactListData, index: number, array: TContactListData[]) => {
    const fullName = formatName(contact?.firstName, contact?.lastName);
    const prevName = formatName(array[index - 1]?.firstName, array[index - 1]?.lastName);
    const showDropCap = !index || prevName[0]?.toLowerCase() !== fullName[0]?.toLowerCase();

    return (
      <Contact
        key={contact.contactId}
        contact={contact}
        showDropCap={showDropCap}
        onCallClick={onCallClick}
        transferDisabled={transferDisabled}
        isTransfer={isTransfer}
        searchTerm={searchTerm}
        isSoftphone={isSoftphone}
        fullName={fullName}
        onTransfer={onTransfer}
        isAdmin={isAdmin}
        onCreateOrUpdateClick={onCreateOrUpdateClick}
        onDeleteContact={onDeleteContact}
        transferringToId={transferringToId}
      />
    );
  };

  const contactElements = contacts.map(generateContactElements);

  return (
    <>
      <DirectoryPlaceHolder
        isAdmin={isAdmin}
        selectedTab={selectedTab}
        searchTerm={searchTerm}
        onClearSearchTerm={onClearSearchTerm}
        loading={loading}
        numberOfContacts={contacts.length}
        onCreateOrUpdateClick={onCreateOrUpdateClick}
      />
      {Boolean(contacts.length) && (
        <div
          role="tabpanel"
          ref={rootRef}
          className={cx(styles['contact-list__container'], {
            [styles['contact-list__container--softphone']]: isSoftphone,
            [styles['contact-list__container--searching']]: Boolean(searchTerm) && selectedTab !== DIRECTORY_TAB.AGENTS
          })}
        >
          {contactElements}
          {!exhausted && (
            <>
              {sentryElement}
              <Loader className={styles['contact-list__container__loader']} relative minimised small />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DirectoryContactList;
