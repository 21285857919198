import { TCRUDProps } from '../../CRUDTableTypes';

export const buildRawClientUrls = ({ serviceUrl, entityPlural, entitySingular, entityParentPath }: TCRUDProps) => {
  const qualifiedParentPath = `${serviceUrl}/${entityParentPath}`;
  const qualifiedEntity = `${qualifiedParentPath}/${entitySingular}`;

  return {
    qualifiedEntity,
    qualifiedEntityWithId: `${qualifiedEntity}/`,
    qualifiedEntities: `${qualifiedParentPath}/${entityPlural}`
  };
};
