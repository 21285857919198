'use client';

import { faQuestion } from '@fortawesome/pro-duotone-svg-icons/faQuestion';
import { AllCommunityModule, ModuleRegistry, colorSchemeLight, themeQuartz } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import cx from 'classnames';

import EmptyPlaceholder from '../../../EmptyPlaceholder';
import Loader from '../../../Loader';
import { TBasicTableProps } from '../sharedTypes';
import { NoRowsSearchEmptyPlaceholder } from './components/NoRowsSearchEmptyPlaceholder';
import { TableToolbar } from './components/TableToolbar';
import { modifyColumnDefs } from './utils/modifyColumnDefs';

import cssVars from 'css/export-vars.module.scss';

import styles from './basic-table.module.scss';

ModuleRegistry.registerModules([AllCommunityModule]);

// Register all Community features
const InternalTable = <TData extends object>({
  agTableProps,
  searchProps,
  emptyPlaceHolderProps,
  rowActions,
  initialLoadState
}: TBasicTableProps<TData>) => {
  const columnDefs = modifyColumnDefs<TData>({
    rowActions,
    // @ts-ignore
    columnDefs: agTableProps?.columnDefs
  });

  if (initialLoadState && initialLoadState.loading) {
    return <Loader relative />;
  }

  if (agTableProps?.rowData && !agTableProps?.rowData?.length) {
    return <EmptyPlaceholder icon={faQuestion} {...emptyPlaceHolderProps} />;
  }

  const themeBase = themeQuartz.withPart(colorSchemeLight).withParams({
    headerColumnResizeHandleColor: cssVars.seeThrough,
    headerBackgroundColor: cssVars.lightGrey,
    headerCellHoverBackgroundColor: cssVars.midGrey,
    headerTextColor: cssVars.darkGrey,
    foregroundColor: cssVars.black,
    wrapperBorder: false,
    headerRowBorder: false,
    borderRadius: cssVars.borderRadiusXLarge,
    rowHeight: '48px',
    rowHoverColor: cssVars.lightBlue
  });

  return (
    <>
      <TableToolbar searchProps={searchProps} />
      <AgGridReact
        {...agTableProps}
        columnDefs={columnDefs}
        suppressCellFocus
        enableCellTextSelection
        alwaysShowVerticalScroll
        alwaysShowHorizontalScroll
        noRowsOverlayComponent={NoRowsSearchEmptyPlaceholder}
        className={cx(styles['basic-table-internal'])}
        theme={themeBase}
      />
    </>
  );
};

/**
 * Sets up basic table instantiation with our default styling overrides
 * loads community addition modules if you are implementing a new type of table Extend this one like how crud table is
 * implemented.
 *
 * We want to set the minimal number of additional props on this to reduce the amount of complexity when using the table
 * components
 */
export const BasicTable = <TData extends object>(props: TBasicTableProps<TData>) => {
  const { rootClassName } = props;
  return (
    <div className={cx(styles['basic-table'], 'flex-column', 'grow', rootClassName)}>
      <InternalTable {...props} />
    </div>
  );
};
