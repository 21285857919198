import { useAuthContext } from 'lib/core/context/AuthProvider';

import { TCRUDDataSourceProps } from './TCRUDDataSourceProps';
import { RawCrudClient } from './dataSourceHelpers/RawCrudClient';
import { useClientControlledDataSource } from './subHooks/useClientControlledDataSource';
import { useServersideControlledDataSource } from './subHooks/useServersideControlledDataSource';

/**
 * top level hook for differentiating between server side and client side controlled data sources.
 * Load the authorized fetch Client as well as the {@link RawCrudClient} client
 * which we use to interact with a standardized API
 * (Serverside Utils for enforcing this consistency not yet implemented)
 *
 *
 * {@link useServersideControlledDataSource}
 *
 * {@link useClientControlledDataSource}
 * */
export const useCRUDDataSource = (dataSourceProps: TCRUDDataSourceProps) => {
  const {
    CRUDProps: { usesServersideActions }
  } = dataSourceProps;
  // client with auth token preAttached
  const { fetch_ } = useAuthContext();

  const crudClient = new RawCrudClient({ fetch: fetch_, CRUDProps: dataSourceProps.CRUDProps });

  return usesServersideActions
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useServersideControlledDataSource({ ...dataSourceProps, crudClient })
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useClientControlledDataSource({ ...dataSourceProps, crudClient });
};
