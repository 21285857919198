import { ValueFormatterParams } from 'ag-grid-community';

//all Functions in this file should take the cellRendererParams and return a tranformed value
// (ValueFormatterParams<TDATA, TValue>) => string

export const toTitleCase = ({ value }: Partial<ValueFormatterParams>) => {
  if (!value) {
    return '';
  }
  return value.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
};
