import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faBoxOpen as defaultEmptyIcon, faRobot as defaultErrorIcon } from '@fortawesome/pro-duotone-svg-icons';
import cx from 'classnames';

import { validateIconType } from '@cloud-wave/neon-common-lib/app';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Button from 'lib/common/components/Button';
import Icon from 'lib/common/components/Icon';
import { IconSizeConstraints } from 'lib/common/components/Icon/Icon';
import Text from 'lib/common/components/Text';

import './empty-placeholder.scss';

const ICON_SIZES = {
  small: 20,
  medium: 25,
  large: 45
};

const getIcon = ({
  icon,
  iconSize,
  error
}: {
  icon: IconDefinition;
  iconSize: keyof typeof ICON_SIZES;
  error?: boolean;
}) => {
  if (error) {
    return (
      <Icon
        className={cx('empty-placeholder__icon', `empty-placeholder__error-icon--${iconSize}`)}
        icon={defaultErrorIcon}
        color="primary"
        size={ICON_SIZES[iconSize]}
        onlyConstrain={IconSizeConstraints.HEIGHT}
      />
    );
  }

  return (
    <Icon
      className="empty-placeholder__icon"
      icon={icon || defaultEmptyIcon}
      color="primary"
      size={ICON_SIZES[iconSize]}
      onlyConstrain={IconSizeConstraints.HEIGHT}
    />
  );
};

const getIconSize = ({
  text,
  subText,
  callToAction,
  isSoftphone,
  iconOnly
}: {
  text?: string | JSX.Element;
  subText?: string | JSX.Element;
  callToAction?: {
    text: string;
    onClick: () => void;
  };
  isSoftphone?: boolean;
  iconOnly?: boolean;
}) => {
  if (iconOnly) {
    return isSoftphone ? 'small' : 'medium';
  }

  if (isSoftphone) {
    return 'small';
  }

  if (callToAction && subText && text) {
    return 'large';
  }

  return 'medium';
};

export type TEmptyPLaceHolderProps = {
  text?: string | JSX.Element;
  subText?: string | JSX.Element;
  isError?: boolean;
  callToAction?: {
    text: string;
    onClick: () => void;
  };
  useEmptyImage?: boolean;
  icon: IconDefinition;
  className?: string;
  iconOnly?: boolean;
};

export default function EmptyPlaceholder({
  text,
  subText = '',
  isError: error,
  callToAction,
  useEmptyImage,
  icon,
  className,
  iconOnly
}: TEmptyPLaceHolderProps) {
  const { isSoftphone } = useLayout();
  const defaultText = error ? 'Oops, something went wrong. Try reloading the page.' : "There's nothing in here yet.";
  const mainText = text || defaultText;
  const iconSize = getIconSize({ text: mainText, subText, callToAction, isSoftphone, iconOnly });
  try {
    validateIconType({ icon, expected: 'fad' });
  } catch (e) {
    console.error(e);
    // Developer facing error
    return <div>You are using the wrong Icon type all EmptyPlaceholder icons must be duo-tone</div>;
  }
  return (
    <div
      data-testId="empty-placeholder"
      className={cx('empty-placeholder', className, {
        'empty-placeholder--icon-only': iconOnly,
        'empty-placeholder--softphone': isSoftphone
      })}
    >
      {useEmptyImage ? null : (
        <div className={cx('empty-placeholder__icon-container', `empty-placeholder__icon-container--${iconSize}`)}>
          {getIcon({ icon, iconSize, error })}
        </div>
      )}
      <div className="empty-placeholder__content">
        {!iconOnly && (
          <Text
            className={cx('empty-placeholder__content__message', {
              'empty-placeholder__message--error': error,
              'mb-5': subText && !error
            })}
            data-testid="empty-placeholder-text"
            type={!subText ? undefined : 'heading3'}
            color="darkGrey"
          >
            {mainText}
          </Text>
        )}
        {subText && !error && !iconOnly && (
          <Text
            className="empty-placeholder__content__message"
            data-testid="empty-placeholder-subtext"
            color="darkGrey"
          >
            {subText}
          </Text>
        )}
        {callToAction && !iconOnly && (
          <Button
            className={cx({ 'mt-15': isSoftphone, 'mt-10': !isSoftphone })}
            styleType="SECONDARY"
            onClick={callToAction.onClick}
            data-testid="empty-placeholder-button"
            size={isSoftphone || !subText ? 'small' : 'medium'}
          >
            {callToAction.text}
          </Button>
        )}
      </div>
    </div>
  );
}
