import { ContactType } from '@cloud-wave/neon-common-lib';

import { TInternalContacts } from '../../../types';

export default function transformContactObject({
  contact,
  defaultType
}: {
  contact?: TInternalContacts;
  defaultType: typeof ContactType.PERSONAL | typeof ContactType.ORGANISATION;
}) {
  // Editing an existing contact
  if (contact?.contactId) {
    return {
      firstName: contact.firstName,
      lastName: contact.lastName,
      phoneNumber: contact.phoneNumber,
      email: contact.email,
      notes: contact.notes,
      type: contact.type,
      jobTitle: contact.jobTitle
    };
  }

  return {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    notes: '',
    jobTitle: '',
    type: defaultType
  };
}
