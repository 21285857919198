import { MILLISECONDS_IN_A_DAY, REFRESH_TOKEN_EXPIRY_STORAGE_KEY } from '../constants';

export default function shouldRefreshToken() {
  // Get refresh token from current session and check if it expires in the next 7 days
  const refreshTokenExp = Number(localStorage.getItem(REFRESH_TOKEN_EXPIRY_STORAGE_KEY));

  if (!refreshTokenExp) {
    return false;
  }

  const now = Date.now();
  const sevenDaysFromNow = now + 7 * MILLISECONDS_IN_A_DAY;
  const oneMinuteFromNow = now + 1000 * 60; // if token expires in next minute, run auth just in case of network turbulence

  return refreshTokenExp > oneMinuteFromNow && refreshTokenExp < sevenDaysFromNow;
}
