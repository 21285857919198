import { DIRECTORY_TAB } from '../../constants/directoryTabs';
import AgentContactList from './Components/AgentContactList';
import DirectoryContactList from './Components/DirectoryContactList';
import { TContactListProps } from './types';

const ContactList = (props: TContactListProps) => {
  const { selectedTab } = props;
  return selectedTab === DIRECTORY_TAB.AGENTS ? <AgentContactList {...props} /> : <DirectoryContactList {...props} />;
};

export default ContactList;
