import { useEffect } from 'react';

export default function useUnload({ fetch_, config }) {
  const unload = () => {
    const body = JSON.stringify({ id: sessionStorage.getItem('user_id') });
    navigator.sendBeacon(`${config.AGENT_SERVICE_URL}/agent/cleanup/`, body);
  };

  useEffect(() => {
    window.addEventListener('unload', unload);

    return () => {
      window.removeEventListener('unload', unload);
    };
  }, [fetch_, config]);
}
