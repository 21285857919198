import { useEffect, useMemo, useRef, useState } from 'react';

import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import cx from 'classnames';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import _debounce from 'lodash.debounce';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import useIsSmallSoftphone from 'lib/common/hooks/useIsSmallSoftphone';

import { ChatBoxInput } from 'lib/common/components/atoms/ChatSendFooter/ChatBoxInput';
import { FileCardEmail } from 'lib/common/components/molecules/FilesEmail';
import RichTextEditor from 'lib/common/components/molecules/RichTextEditor';

import UploadedFileStatus from 'lib/common/constants/files/UploadedFileStatus';

import TaskContentTypes from 'lib/common/types/TaskContentTypes';
import getTemplatedResponseQueueIds from 'lib/common/utils/templatedResponse/getTemplatedResponseQueueIds';
import toast from 'lib/common/utils/toast';

import ChatSendFooterActions from './ChatSendFooterActions';
import { ConnectFileTypes, MAX_MESSAGE_LENGTH, MaxConnectAttachmentFileSize } from './constants';

import './chat-send-footer.scss';

const UPDATE_TASK_CONTENT_DEBOUNCE_MS = 500;

const getMarkdownString = (editor) => {
  const stringifiedEditorState = JSON.stringify(editor.getEditorState().toJSON());
  const parsedEditorState = editor.parseEditorState(stringifiedEditorState);

  return parsedEditorState.read(() => $convertToMarkdownString(TRANSFORMERS));
};

function ChatSendFooterInternal({ sendMessage, sendTypingEvent, task, sendAttachment }) {
  const { content, attachment } = task.chatDraftMessage || {};
  const {
    actions: { setTaskContent },
    state: { selectedTaskId }
  } = useContactContext();
  const { isSoftphone } = useLayout();
  const isSmallSoftphone = useIsSmallSoftphone();

  const [editor] = useLexicalComposerContext();
  const [message, setMessage] = useState(content || '');
  const [file, setFile] = useState<File | null>(attachment || null);
  const [typing, setTyping] = useState(false);
  const [sending, setSending] = useState(false);

  const messageInputRef = useRef<HTMLTextAreaElement | null>(null);
  const attachmentInputRef = useRef<HTMLInputElement | null>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const editorFocusRef = useRef<(() => void) | null>(null);

  const onChangeMessage = useMemo(
    () =>
      _debounce((message: string, file: File | null) => {
        setTaskContent(selectedTaskId, TaskContentTypes.CHAT_DRAFT_MESSAGE, { content: message, attachment: file });
      }, UPDATE_TASK_CONTENT_DEBOUNCE_MS),
    []
  );

  useEffect(() => onChangeMessage(message, file), [message, file]);

  const clearFile = () => {
    if (attachmentInputRef && attachmentInputRef.current) {
      attachmentInputRef.current.value = '';
    }

    setFile(null);
  };

  const queueIds = getTemplatedResponseQueueIds(task);

  const onChangeInputMessage = ({ target: { value } }) => {
    setMessage(value);

    if (typing) {
      return;
    }

    sendTypingEvent();
    setTyping(true);
  };

  const onSendMessage = async () => {
    if (!message && !file) {
      return;
    }

    setSending(true);
    setTyping(false);

    try {
      if (message) {
        const messageString = task?.canUseRichText ? getMarkdownString(editor) : message;
        await sendMessage(messageString, task?.canUseRichText);
      }

      if (file) {
        await sendAttachment(file);
      }
    } catch {
      toast('error', 'Something went wrong sending this message, please try again.');
    }

    setMessage('');
    clearFile();

    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  };

  const onAddTemplate = (templateContent: string) => {
    const messageString = task?.canUseRichText ? getMarkdownString(editor) : message;
    const messageSeparator = messageString.slice(-1) === '\n' ? '' : ' ';
    const newMessage = `${messageString}${messageString && messageSeparator}${templateContent}`;

    if (newMessage.length > MAX_MESSAGE_LENGTH) {
      toast('warning', `Adding this template would exceed the max character limit of ${MAX_MESSAGE_LENGTH}`);
      return;
    }

    editor.update(() => {
      const stateToChange = editor.getEditorState().toJSON();
      const currentStateChildren = stateToChange.root.children;
      const childrenToAppend = [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: 'normal',
              style: '',
              text: templateContent,
              type: 'text',
              version: 1
            }
          ],
          direction: 'ltr',
          format: '',
          indent: 0,
          type: 'paragraph',
          version: 1
        }
      ];
      stateToChange.root.children = [...currentStateChildren, ...childrenToAppend];

      const newState = editor.parseEditorState(stateToChange);

      editor.setEditorState(newState);
    });

    setMessage(newMessage);
  };

  const isSendDisabled = () => {
    const messageString = task?.canUseRichText ? getMarkdownString(editor) : message;

    return (!messageString || messageString.length > MAX_MESSAGE_LENGTH) && !file;
  };

  const onFileInputChange = (event) => {
    const {
      target: { files }
    } = event;

    if (!files || !files.length) {
      return;
    }
    const fileInternal = files[0];
    const filesize = fileInternal.size;

    const bytesToMegabytes = (bytes: number) => bytes / 1_000_000;

    if (filesize > MaxConnectAttachmentFileSize) {
      toast(
        'error',
        `File must be smaller than ${bytesToMegabytes(MaxConnectAttachmentFileSize)} MB, file was ${bytesToMegabytes(
          filesize
        ).toFixed(1)} MB`
      );
      return;
    }
    if (filesize === 0) {
      toast('error', 'Cannot attach an empty file');
      return;
    }

    setFile(fileInternal);
  };

  return (
    <div
      className={cx('chat-send-footer', {
        'chat-send-footer--softphone': isSoftphone,
        'chat-send-footer--softphone-editor-input': isSoftphone && !isSmallSoftphone,
        'chat-send-footer--small-softphone': isSmallSoftphone
      })}
      onClick={() => {
        editorFocusRef?.current?.();
        messageInputRef?.current?.focus?.();
      }}
    >
      <input
        type="file"
        id="upload-files"
        ref={attachmentInputRef}
        className="hide"
        accept={ConnectFileTypes.join(',')}
        onChange={onFileInputChange}
        data-testid="upload-files"
        aria-label="Upload filed"
      />
      {file && (
        <FileCardEmail
          file={{
            name: file?.name || '',
            size: file?.size || 0,
            uploadPercent: 0,
            status: UploadedFileStatus.UPLOADING,
            cancelSource: null,
            type: '',
            contextId: ''
          }}
          onDelete={clearFile}
        />
      )}
      <ChatBoxInput
        message={message}
        messageInputRef={messageInputRef}
        onChangeInputMessage={onChangeInputMessage}
        sending={sending}
        submitButtonRef={submitButtonRef}
        canUseRichText={task?.canUseRichText}
        editorFocusRef={editorFocusRef}
      />
      <ChatSendFooterActions
        task={task}
        attachmentInputRef={attachmentInputRef}
        onAddTemplate={onAddTemplate}
        queueIds={queueIds}
        setSending={setSending}
        onSendMessage={onSendMessage}
        disableSend={isSendDisabled()}
        submitButtonRef={submitButtonRef}
        messageInputRef={messageInputRef}
      />
    </div>
  );
}

export default function ChatSendFooter({ sendMessage, sendTypingEvent, task, sendAttachment }) {
  return (
    <RichTextEditor.LexicalComposer initialConfig={RichTextEditor.defaultEditorConfig}>
      <ChatSendFooterInternal
        sendMessage={sendMessage}
        sendTypingEvent={sendTypingEvent}
        task={task}
        sendAttachment={sendAttachment}
      />
    </RichTextEditor.LexicalComposer>
  );
}
