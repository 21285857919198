import { ICellRendererParams } from 'ag-grid-community';
import { format } from 'date-fns';

import { useAgentPreferencesContext } from 'lib/common/contexts/AgentPreferenceContext';

import Text from 'lib/common/components/Text';

export const DateCell = () => {
  //So we only call state once
  const {
    state: { preferredDateFormat }
  } = useAgentPreferencesContext();

  return ({ value }: Partial<ICellRendererParams>) => {
    if (!value) {
      return '';
    }

    const dateObj = new Date(value);

    return <Text tooltip={dateObj.toString()}>{format(dateObj, preferredDateFormat)}</Text>;
  };
};
