import {
  faAddressBook,
  faBrowser,
  faCalendarDay,
  faChartLine,
  faClockRotateLeft,
  faGrid2,
  faLayerGroup,
  faPaintbrushPencil,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';

import { Permissions } from '@cloud-wave/neon-common-lib';
import {
  PAGE_CONFIGURATIONS,
  PAGE_ROUTES,
  SOFTPHONE_PAGE_CONFIGURATIONS,
  SOFTPHONE_PAGE_ROUTES
} from '@cloud-wave/neon-common-lib/app';

import { faDialpad } from '../customIcons';
import { TConfig } from '../types/Config';
import { SoftphoneNavOption } from '../types/SoftphoneNavOption';

export const SOFTPHONE_LANDING_ROUTE: SoftphoneNavOption = {
  link: SOFTPHONE_PAGE_ROUTES.LANDING,
  icon: faLayerGroup,
  name: 'Tasks'
};

export const SOFTPHONE_DEFAULT_NAV_OPTIONS = (config: TConfig): SoftphoneNavOption[] => [
  {
    link: SOFTPHONE_PAGE_ROUTES.DIALPAD,
    icon: faDialpad,
    name: SOFTPHONE_PAGE_CONFIGURATIONS[SOFTPHONE_PAGE_ROUTES.DIALPAD].title
  },
  {
    link: SOFTPHONE_PAGE_ROUTES.DIRECTORY,
    icon: faAddressBook,
    name: SOFTPHONE_PAGE_CONFIGURATIONS[SOFTPHONE_PAGE_ROUTES.DIRECTORY].title
  },
  {
    link: SOFTPHONE_PAGE_ROUTES.INTERACTION_HISTORY,
    icon: faClockRotateLeft,
    name: SOFTPHONE_PAGE_CONFIGURATIONS[SOFTPHONE_PAGE_ROUTES.INTERACTION_HISTORY].title,
    permission: Permissions.CALL_HISTORY
  },
  {
    link: SOFTPHONE_PAGE_ROUTES.AGENT_STATS,
    icon: faChartLine,
    name: SOFTPHONE_PAGE_CONFIGURATIONS[SOFTPHONE_PAGE_ROUTES.AGENT_STATS].title
  },
  {
    icon: faGrid2,
    name: 'Desktop Shortcuts',
    childOptions: [
      {
        link: PAGE_ROUTES.REPORTS_WALLBOARD,
        isolated: true,
        icon: faBrowser,
        name: PAGE_CONFIGURATIONS[PAGE_ROUTES.REPORTS_WALLBOARD].title,
        permission: Permissions.REPORTS_WALLBOARD
      },
      {
        link: PAGE_ROUTES.REPORTS_PERFORMANCE_TODAY,
        isolated: true,
        icon: faCalendarDay,
        name: PAGE_CONFIGURATIONS[PAGE_ROUTES.REPORTS_PERFORMANCE_TODAY].title,
        requiresAdmin: true,
        permission: Permissions.REPORTS_PERFORMANCE_TODAY
      },
      {
        link: PAGE_ROUTES.REPORTS_ANALYTICS,
        isolated: true,
        icon: faChartLine,
        name: PAGE_CONFIGURATIONS[PAGE_ROUTES.REPORTS_ANALYTICS].title,
        requiresAdmin: true,
        permission: Permissions.REPORTS_ANALYTICS
      },
      {
        link: `https://${config.CONNECT_REGION}.quicksight.aws.amazon.com/sn/start/dashboards`,
        external: true,
        icon: faPaintbrushPencil,
        name: 'Custom Reports',
        requiresAdmin: true,
        permission: Permissions.REPORTS_CUSTOM
      },
      {
        link: PAGE_ROUTES.USER_SETTINGS,
        isolated: true,
        icon: faUsers,
        name: PAGE_CONFIGURATIONS[PAGE_ROUTES.USER_SETTINGS].title,
        requiresAdmin: true
      }
    ]
  }
];
