import cx from 'classnames';

import { createEnumFromArray } from '@cloud-wave/neon-common-lib';

import Text from 'lib/common/components/Text';

import styles from './status.module.scss';

const statusTypes = ['SUCCESS', 'NEUTRAL', 'DANGER'] as const;

export const StatusTypes = createEnumFromArray(statusTypes);

export type TStatusType = typeof statusTypes[number];

type TStatus = { children: string; type: TStatusType };

type TTypedStatus = Omit<TStatus, 'type'>;

export default function Status({ children, type }: TStatus) {
  return (
    <div className={styles.status}>
      <span className={cx(styles['status__dot'], styles[`status__dot--${type.toLowerCase()}`])} />
      <Text className="text--small">{children}</Text>
    </div>
  );
}

Status.StatusTypes = StatusTypes;

Status.Success = (props: TTypedStatus) => <Status {...props} type={StatusTypes.SUCCESS} />;
Status.Neutral = (props: TTypedStatus) => <Status {...props} type={StatusTypes.NEUTRAL} />;
Status.DANGER = (props: TTypedStatus) => <Status {...props} type={StatusTypes.DANGER} />;
