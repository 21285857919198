import { useCallback } from 'react';

import _debounce from 'lodash.debounce';
import _throttle from 'lodash.throttle';
import { match } from 'ts-pattern';

import { createEnumFromArray } from '@cloud-wave/neon-common-lib';

// intervals etc could be added to this in the future
const rateLimitMethods = ['throttle', 'debounce', 'none'] as const;
export const rateLimitMethod = createEnumFromArray(rateLimitMethods);

type TRateLimitMethod = typeof rateLimitMethods[number];
export type TRateLimiterConfiguration<T> = {
  limitingMethod: TRateLimitMethod;
  limitingOptions?: {
    leading?: boolean;
    trailing?: boolean;
  };
  delayMs?: number;
  callBackDeps?: any[];
  functionCall: T;
};

const DEFAULT_DELAY = 1000;

export const useRateLimiter = <T extends (...args: any) => any>({
  callBackDeps = [],
  delayMs = DEFAULT_DELAY,
  limitingOptions,
  limitingMethod,
  functionCall
}: TRateLimiterConfiguration<T>) => {
  const wrappedMethod = match(limitingMethod)
    .with(rateLimitMethod.none, () => functionCall)
    .with(rateLimitMethod.debounce, () => _debounce(functionCall, delayMs, limitingOptions))
    .with(rateLimitMethod.throttle, () => _throttle(functionCall, delayMs, limitingOptions))
    .exhaustive();

  //Memos the chosen function
  return useCallback(wrappedMethod, [limitingMethod, ...callBackDeps]);
};
