import { FileCardEmail } from 'lib/common/components/molecules/FilesEmail';

import UploadedFileStatus from 'lib/common/constants/files/UploadedFileStatus';

import { TAttachment } from 'lib/common/types/email/TAttachment';

export default function OriginalAttachment({
  attachment,
  onRemove,
  className
}: {
  className?: string;
  attachment: TAttachment;
  onRemove: (id: number) => Promise<void> | void;
}) {
  return (
    <FileCardEmail
      id={attachment.id}
      className={className}
      file={{
        uploadPercent: 100,
        status: UploadedFileStatus.COMPLETE,
        name: attachment.filename || 'File',
        size: attachment.content.byteLength,
        type: attachment.mimeType,
        cancelSource: null,
        contextId: 'na'
      }}
      onDelete={() => onRemove(attachment.id)}
      highlighted
      suffix="Original File"
    />
  );
}
