import { useEffect, useState } from 'react';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import requestErrorToast from 'lib/common/utils/toast/requestErrorToast';

import { TQueryParams } from '../../CRUDTableTypes';
import { TCRUDDataSourcePropsInternal } from '../TCRUDDataSourceProps';

/**
 * returns agGrid props preset to work with the crud and base table implementations such as client side;
 * search, filtering and sorting
 *
 * expects the api to take a pageSize of all to load all rows at once
 * */
export const useClientControlledDataSource = ({
  CRUDProps: { entitySingular },
  initialLoadState,
  dataModificationProps: { search },
  crudClient
}: TCRUDDataSourcePropsInternal) => {
  const [entities, setEntities] = useState([]);

  const fetchFailed = ({ message, requestId }: { message: string; requestId?: string }) => {
    requestErrorToast({ errorMessage: message, errorReference: requestId });
    setEntities([]);
  };

  const fetchAllEntities = async () => {
    try {
      const { serverEntities } = await crudClient.list({
        queryParams: {
          pageSize: 'all'
        }
      });

      setEntities(serverEntities);
    } catch (e: any) {
      fetchFailed({ message: e.message || 'server Error occurred', requestId: e.requestId });
    } finally {
      if (initialLoadState && initialLoadState.loading) {
        initialLoadState.setLoading(false);
      }
    }
  };

  const deleteEntity = async (targetEntityId: string, queryParams?: TQueryParams) => {
    try {
      await crudClient.delete({ id: targetEntityId, queryParams });
      await fetchAllEntities();
    } catch (error) {
      logger.error(LogEvents.AGENT_MANAGEMENT.DELETE.FAIL, { error, targetEntityId, queryParams });
      requestErrorToast({ errorMessage: `failed to delete ${entitySingular}` });
    }
  };

  useEffect(() => {
    fetchAllEntities();
  }, []);

  return {
    agDataSourceProps: { rowData: entities, quickFilterText: search },
    refetchDataSource: fetchAllEntities,
    deleteEntity
  };
};
