import { ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';

import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import styles from './dropzone.module.scss';

export default function DropZone({
  handleFileArray,
  children,
  className
}: {
  handleFileArray: (files: File[]) => Promise<void>;
  children?: ReactNode;
  className?: string;
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleFileArray, noClick: true });

  return (
    <div className={cx(className, styles.dropzone)} {...getRootProps()}>
      <input {...getInputProps()} data-testid="upload-files-dropzone" aria-label="Drop your files here" />
      {isDragActive ? (
        <div className={styles['dropzone--active']}>
          <Icon icon={faCloudUpload} color="darkGrey" size={40} />
          <Text className="mt-10" color="darkGrey" noSelect>
            Drop your files here
          </Text>
        </div>
      ) : null}
      {children}
    </div>
  );
}
