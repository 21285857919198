import { useState } from 'react';

import {
  faArrowRight,
  faComment,
  faCopy,
  faEnvelope,
  faKeyboard,
  faPaste,
  faPen
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faCircleQuestion as farCircleQuestion,
  faCommentDots as farCommentDots,
  faEnvelope as farEnvelope
} from '@fortawesome/pro-regular-svg-icons';
import { faMessageDots } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { match } from 'ts-pattern';

import { useConfigContext } from 'lib/core/config';

import useIsSmallSoftphone from 'lib/common/hooks/useIsSmallSoftphone';
import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import Badge from 'lib/common/components/Badge';
import Button from 'lib/common/components/Button';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import { HELP_LINKS } from 'lib/common/constants/help';

import useIsMediumScreen from 'lib/common/mediaQueries/useIsMediumScreen';
import toast from 'lib/common/utils/toast';

import { MfaDestination, MfaDestinations } from '../../../types/AuthState';
import { InfoCardGroup, InfoCardGroupGap, InfoCardSize } from '../../InfoCard';

import styles from './select-mfa.module.scss';

const Main = ({
  mfaDestinations,
  mfaUiSelection,
  setMfaUiSelection,
  selectMfaDestination,
  restartAuthFlow,
  openInfo
}: {
  mfaDestinations: { obfuscatedEmail: string; obfuscatedPhone: string };
  mfaUiSelection: MfaDestination;
  setMfaUiSelection: (mfaUiSelection: MfaDestination) => void;
  selectMfaDestination: (dest: 'email' | 'sms') => Promise<void>;
  restartAuthFlow: () => Promise<void>;
  openInfo: () => void;
}) => {
  const isSoftphone = useIsSoftphoneQuery();
  const isMediumScreen = useIsMediumScreen();

  const [loading, setLoading] = useState(false);

  return (
    <div className={cx(styles['select-mfa'], { [styles['select-mfa--softphone']]: isSoftphone })}>
      <Text type={'heading1'}>Just Confirming It's You</Text>
      <Text>
        Click the button below to{' '}
        {match(mfaUiSelection)
          .with(MfaDestinations.email, () => 'email')
          .with(MfaDestinations.sms, () => 'send')
          .exhaustive()}{' '}
        a verification code to{' '}
        <Badge
          type="SECONDARY"
          label={match(mfaUiSelection)
            .with(MfaDestinations.email, () => mfaDestinations.obfuscatedEmail)
            .with(MfaDestinations.sms, () => mfaDestinations.obfuscatedPhone)
            .exhaustive()}
        />
        . You can enter it on the next page.
      </Text>

      <div className={styles['select-mfa__buttons']}>
        {(isSoftphone || isMediumScreen) && (
          <Button styleType="SECONDARY" endIcon={farCircleQuestion} onClick={openInfo}>
            How It Works
          </Button>
        )}
        <Button
          busy={loading}
          onClick={async () => {
            setLoading(true);
            try {
              await selectMfaDestination(mfaUiSelection);
            } catch (error: any) {
              if (error.toString().includes('Max send attempts')) {
                toast(
                  'error',
                  "Oops, it looks like you've hit your resend limit. Give it a few minutes and try again."
                );

                await restartAuthFlow();
                setLoading(false);
                return;
              }

              if (error.toString().includes('Geo-Permissions')) {
                toast(
                  'error',
                  'Oops, something went wrong sending your verification code. Please try again, try another method or get in touch with your Administrator.'
                );

                await restartAuthFlow();
                setLoading(false);
                return;
              }

              throw error;
            }
          }}
          endIcon={match(mfaUiSelection)
            .with(MfaDestinations.email, () => farEnvelope)
            .with(MfaDestinations.sms, () => farCommentDots)
            .exhaustive()}
        >
          Send
        </Button>
        <div className={styles['select-mfa__link-box']}>
          <Text
            type="link"
            onClick={() => {
              setLoading(false);
              setMfaUiSelection(
                match(mfaUiSelection)
                  .with(MfaDestinations.email, () => MfaDestinations.sms)
                  .with(MfaDestinations.sms, () => MfaDestinations.email)
                  .exhaustive()
              );
            }}
          >
            Verify with{' '}
            {match(mfaUiSelection)
              .with(MfaDestinations.email, () => 'SMS')
              .with(MfaDestinations.sms, () => 'email')
              .exhaustive()}
          </Text>
        </div>
      </div>
    </div>
  );
};

const Info = ({ mfaUiSelection }: { mfaUiSelection: MfaDestination }) => {
  const isSoftphone = useIsSoftphoneQuery();
  const isSmallSoftphone = useIsSmallSoftphone();
  const isMediumScreen = useIsMediumScreen();
  const {
    config: {
      BRAND: { helpDocsUrl }
    }
  } = useConfigContext();

  return (
    <InfoCardGroup
      alternating={!(isSoftphone || isMediumScreen)}
      gap={isSoftphone || isMediumScreen ? InfoCardGroupGap.MEDIUM : InfoCardGroupGap.LARGE}
      minimal={isSmallSoftphone}
      small={isSoftphone}
      groupSize={isSoftphone || isMediumScreen ? InfoCardSize.SMALL : InfoCardSize.LARGE}
      className={cx(styles['select-mfa__cards'], { [styles['select-mfa__cards--softphone']]: isSoftphone })}
      cards={[
        {
          icon: match(mfaUiSelection)
            .with(MfaDestinations.email, () => faEnvelope)
            .with(MfaDestinations.sms, () => faComment)
            .exhaustive(),
          text: (
            <Text>
              You'll receive a <Text.Bold inline>verification code</Text.Bold> <Icon icon={faMessageDots} size={15} />{' '}
              {match(mfaUiSelection)
                .with(MfaDestinations.email, () => (
                  <>
                    to your <Text.Bold>email account</Text.Bold> from no-reply@cw-neon.com.
                  </>
                ))
                .with(MfaDestinations.sms, () => (
                  <>
                    on your <Text.Bold>mobile phone</Text.Bold>.
                  </>
                ))
                .exhaustive()}{' '}
              <Text
                href={`${helpDocsUrl}${match(mfaUiSelection)
                  .with(MfaDestinations.email, () => HELP_LINKS.SIGN_IN.EMAIL)
                  .with(MfaDestinations.sms, () => HELP_LINKS.SIGN_IN.SMS)
                  .exhaustive()}`}
              >
                Learn more
              </Text>
              .
            </Text>
          )
        },
        {
          icon: match(mfaUiSelection)
            .with(MfaDestinations.email, () => faCopy)
            .with(MfaDestinations.sms, () => faPen)
            .exhaustive(),
          text: (
            <Text>
              {match(mfaUiSelection)
                .with(MfaDestinations.email, () => (
                  <>
                    <Text.Bold inline>Copy</Text.Bold> or make a note
                  </>
                ))
                .with(MfaDestinations.sms, () => <Text.Bold>Make a note</Text.Bold>)
                .exhaustive()}{' '}
              of your verification code <Icon icon={faMessageDots} size={15} />.
            </Text>
          )
        },
        {
          icon: match(mfaUiSelection)
            .with(MfaDestinations.email, () => faPaste)
            .with(MfaDestinations.sms, () => faKeyboard)
            .exhaustive(),
          text: (
            <Text>
              {match(mfaUiSelection)
                .with(MfaDestinations.email, () => (
                  <>
                    <Text.Bold inline>Paste</Text.Bold> or type
                  </>
                ))
                .with(MfaDestinations.sms, () => <Text.Bold>Type</Text.Bold>)
                .exhaustive()}{' '}
              your code in the boxes provided.
            </Text>
          )
        },
        {
          icon: faArrowRight,
          text: (
            <Text>
              Click <Text.Bold inline>Continue</Text.Bold> and we'll finish signing you in.
            </Text>
          )
        }
      ]}
    />
  );
};

export const SelectMFA = { Main, Info };
