import { ReactNode } from 'react';

import cx from 'classnames';
import { match } from 'ts-pattern';

import usePageProps from '../../hooks/usePageProps';
import { PageContentContainer } from '../types/pages';
import { ControlledPageContainer } from './ContentContainer';
import Header from './Header';
import Sidebar from './Sidebar';

import '../styles/core-layout.scss';

interface ICoreLayout {
  user: any;
  children: ReactNode;
}

export default function CoreLayout({ user, children }: ICoreLayout) {
  const { pageContentContainer } = usePageProps();

  const pageContent = match(pageContentContainer)
    .with(PageContentContainer.FREE_FORM, () => children)
    .with(PageContentContainer.CONTROLLED, () => <ControlledPageContainer>{children}</ControlledPageContainer>)
    .exhaustive();

  return (
    <div className={cx('core-layout')}>
      <Header user={user} />
      <div className={cx('core-layout__content')}>
        <div className="sidebar">
          <Sidebar user={user} />
        </div>
        <div className={cx('core-layout__content__right')}>{pageContent}</div>
      </div>
    </div>
  );
}
