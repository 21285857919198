import { useState } from 'react';

import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { AgGridReactProps } from 'ag-grid-react';
import _get from 'lodash.get';

import { TUserWithConnect } from '@cloud-wave/neon-common-lib';

import { rateLimitMethod } from 'lib/common/hooks/useRateLimiter';

import { PopoverMenuItemType } from 'lib/common/components/PopoverMenuItem/PopoverMenuItem';

import { BasicTable } from '../BasicTable';
import { TRowActions, TTableSearchProps } from '../sharedTypes';
import { TCRUDTableProps } from './CRUDTableTypes';
import { DeleteEntityModal } from './components/DeleteEntityModal';
import { useCRUDDataSource } from './hooks/useCRUDDataSource';

/**
 * This table is for out basic CRUD operations.
 * Currently, supports postgres databases, Exposing minimal agGrid Configuration options as it should be consistent,
 * across all crud tables.
 *
 * Should you need changes, carefully consider whether expanding this table to accommodate is the
 * right decision or creating a new one would be more appropriate.
 *
 * When creating a table, choosing whether the table is serverside controlled or locally controlled is important and
 * should not ever change at runtime as we are somewhat hacking in a ternary hook.{@link TCRUDProps}
 *
 * @see {@link useCRUDDataSource} which splits between serverside and clientside control
 *
 * future Improvements:
 * RDS crud table preset with state management for
 * filters
 * page management
 * */
export const CRUDTable = <TCRUDEntity extends object>(props: TCRUDTableProps<TCRUDEntity>) => {
  // don't allow agTableProps to be passed to crud table only column defs
  const { CRUDProps, columnDefs, emptyPlaceHolderProps, rootClassName, searchProps, initialLoadState } = props;
  const { deleteConfirmationModalOptions, entityIdField } = CRUDProps;
  const getEntityId = (data: any) => {
    return _get(data, entityIdField);
  };

  const [deleteTarget, setDeleteTarget] = useState('');
  const [search, setSearch] = useState('');

  const searchDefault: TTableSearchProps = {
    onSearch: setSearch,
    placeholder: 'Search',
    changeBehaviour: { limitingMethod: rateLimitMethod.debounce },
    ...searchProps
  };

  const { agDataSourceProps, deleteEntity } = useCRUDDataSource({
    CRUDProps,
    dataModificationProps: { search },
    initialLoadState: initialLoadState
  });

  const onDeleteClose = () => {
    if (!deleteConfirmationModalOptions) {
      return;
    }

    setDeleteTarget('');
    const {
      deleteParamState: [, setDeleteParams],
      deleteParamsInitialState = {}
    } = deleteConfirmationModalOptions;
    setDeleteParams(deleteParamsInitialState);
  };

  const onDeleteConfirm = async () => {
    if (!deleteConfirmationModalOptions) {
      return;
    }

    const {
      deleteParamState: [deleteParams]
    } = deleteConfirmationModalOptions;

    await deleteEntity(deleteTarget, deleteParams);
    onDeleteClose();
  };

  const crudSpecificAgOverrides: AgGridReactProps = {
    // @ts-ignore
    columnDefs,
    ...agDataSourceProps,
    noRowsOverlayComponentParams: {
      noRowsMessageFunc: () => search
    },
    suppressRowHoverHighlight: true, //TODO once bulk delete etc is implemented remove this suppression
    pagination: true,
    paginationPageSize: props.CRUDProps.defaultPageSize
  };

  const rowActions: TRowActions<TUserWithConnect> = [
    { text: 'Edit', icon: faEdit, onClick: () => {} },
    { type: 'separator' },
    {
      text: 'Remove',
      icon: faTrash,
      type: PopoverMenuItemType.DANGER,
      onClick: (data) => {
        const entityId = getEntityId(data);
        if (!deleteConfirmationModalOptions) {
          return deleteEntity(entityId);
        }

        setDeleteTarget(entityId);
      }
    }
  ];

  return (
    <>
      <DeleteEntityModal
        deleteConfirmationModalOptions={deleteConfirmationModalOptions}
        onDelete={onDeleteConfirm}
        deleteTarget={deleteTarget}
        onClose={onDeleteClose}
      />
      <BasicTable
        agTableProps={crudSpecificAgOverrides}
        emptyPlaceHolderProps={emptyPlaceHolderProps}
        searchProps={searchDefault}
        rootClassName={rootClassName}
        rowActions={rowActions}
        initialLoadState={initialLoadState}
      />
    </>
  );
};

/*
 * future plans for table:
 *  - multi select for bulk delete/ bulk actions + integration with the use crud
 *  - column definition changer
 *  - print (export as blah)
 *  - ask alex what he wants the per-row loading state to look like
 *    - https://www.ag-grid.com/javascript-data-grid/component-loading-cell-renderer/
 * */
