import FilesEmail from 'lib/common/components/molecules/FilesEmail';
import UploadFilesEmail from 'lib/common/components/molecules/UploadFilesEmail';
import { UploadFilesTypesEmail } from 'lib/common/components/molecules/UploadFilesEmail';

import UploadedFileType from 'lib/common/constants/files/UploadedFileType';

import { TAttachment } from 'lib/common/types/email/TAttachment';

import styles from './attachments-modal.module.scss';

export default function AttachmentsModal({
  taskId,
  originalAttachments,
  onRemoveOriginalAttachment,
  baseFileSizeTotal
}: {
  taskId: string;
  originalAttachments?: TAttachment[];
  onRemoveOriginalAttachment: (id: number) => void;
  baseFileSizeTotal?: number;
}) {
  return (
    <UploadFilesEmail
      contextId={taskId}
      uploadedFileType={UploadedFileType.EMAIL_ATTACHMENT}
      type={UploadFilesTypesEmail.DROP_ZONE}
      className={styles['attachments-modal']}
      baseFileSizeTotal={baseFileSizeTotal}
    >
      <div className="flex grow column align-start">
        <FilesEmail
          contextId={taskId}
          uploadedFileType={UploadedFileType.EMAIL_ATTACHMENT}
          originalAttachments={originalAttachments}
          onRemoveOriginalAttachment={onRemoveOriginalAttachment}
        />
      </div>
      <UploadFilesEmail
        className={styles['attachments-modal__upload-button']}
        contextId={taskId}
        uploadedFileType={UploadedFileType.EMAIL_ATTACHMENT}
        type={UploadFilesTypesEmail.BUTTON}
        baseFileSizeTotal={baseFileSizeTotal}
      />
    </UploadFilesEmail>
  );
}
