import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { filesize } from 'filesize';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

type TExceededSizeLimit = {
  totalEmailSize: number;
  contentTotalSize: number;
  originalAttachmentTotalSize: number;
  newAttachmentTotalSize: number;
};

export default function ExceededSizeLimit({
  totalEmailSize,
  contentTotalSize,
  originalAttachmentTotalSize,
  newAttachmentTotalSize
}: TExceededSizeLimit) {
  const { isSoftphone } = useLayout();

  return (
    <div>
      <Text data-testid="email-too-large-text">
        Your email is too large to send. To proceed, ensure the total size of your content and inline images is{' '}
        <Text inline bold>
          5 MB
        </Text>{' '}
        or less, and the overall email, including attachments, stays below{' '}
        <Text inline bold>
          20 MB
        </Text>
        .
      </Text>
      <Text type={isSoftphone ? 'heading3' : 'heading2'} className="mt-30">{`Size Breakdown (${filesize(
        totalEmailSize
      )})`}</Text>
      <Text className="flex mt-20 next-to-5">
        <Text medium inline="flex" className="flex next-to-5">
          Email Content{' '}
          <Icon
            color="darkGrey"
            icon={faCircleQuestion}
            tooltip="Content includes your email body and any inline images."
            size={15}
          />
        </Text>
        : {filesize(contentTotalSize)}
      </Text>
      <Text className="flex mt-10 next-to-5">
        <Text medium inline="flex" className="flex next-to-5">
          Attachments{' '}
          <Icon
            color="darkGrey"
            icon={faCircleQuestion}
            tooltip="Any externally attached files. Inline images are not in this size."
            size={15}
          />
        </Text>
        : {filesize(originalAttachmentTotalSize + newAttachmentTotalSize)}
      </Text>
    </div>
  );
}
