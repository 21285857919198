import MAX_EMAIL_SIZE_BYTES from 'lib/common/constants/email/maxEmailSizeBytes';

import UploadedFile from 'lib/common/types/UploadedFile';

import BLACKLISTED_FILE_EXTENSIONS from '../constants/blacklistedFileExtensions';
import ERROR_MESSAGES from '../constants/errorMessages';

// Matches server side s3 key validation
const FILENAME_KEY_REGEX = new RegExp("^[a-zA-Z0-9!_.*'()-]+(/[a-zA-Z0-9!_.*'()-]+)*$");

const MAX_FILE_NUMBER = 10;

export default function validateFiles(
  files: File[],
  currentAttachments: Record<string, UploadedFile>,
  baseFileSizeTotal = 0
) {
  if (files.length > MAX_FILE_NUMBER) {
    return ERROR_MESSAGES.MAX_FILES;
  }

  const hasFileWithBlacklistedExtension = files.some(({ name }) => {
    const fileExtension = name.split('.').pop();

    if (!fileExtension) {
      return false;
    }

    return BLACKLISTED_FILE_EXTENSIONS.includes(fileExtension);
  });

  if (hasFileWithBlacklistedExtension) {
    return ERROR_MESSAGES.FILE_TYPES;
  }

  // Ignore spaces when checking file names
  const hasInvalidFileName = files.some((file) => !FILENAME_KEY_REGEX.test(file.name.replaceAll(' ', '')));

  if (hasInvalidFileName) {
    return ERROR_MESSAGES.FILENAMES;
  }

  const newTotalFileSize =
    Object.values(currentAttachments).reduce((totalSize, { size }) => totalSize + size, 0) +
    files.reduce((totalSize, { size }) => totalSize + size, 0);

  if (newTotalFileSize + baseFileSizeTotal > MAX_EMAIL_SIZE_BYTES) {
    return ERROR_MESSAGES.FILE_SIZE_LIMIT;
  }

  return null;
}
