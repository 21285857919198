import { TContactListData } from '../hooks/usePaginatedLoad';

function getNameWithoutSpaces(name?: string) {
  if (!name) {
    return '';
  }

  return name.replaceAll(' ', '');
}

export default function getAvatarNameFromContact(contact?: TContactListData) {
  const { firstName, lastName } = contact || { firstName: '', lastName: '' };

  return `${getNameWithoutSpaces(firstName)} ${getNameWithoutSpaces(lastName)}`.trim();
}
