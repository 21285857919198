import { CognitoUser } from 'amazon-cognito-identity-js';

import { useState } from 'react';

import { captureException } from '@sentry/react';
import { Auth } from 'aws-amplify';
import { jwtDecode } from 'jwt-decode';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

const EXPIRY_THRESHOLD_MILLISECONDS = 30 * 1000;

export const useAuthSession = () => {
  const [signedOut, setSignedOut] = useState<boolean>(false);

  const getToken = async () => {
    const currentSession = await Auth.currentSession().catch((error) => {
      logger.warn(LogEvents.AUTH.REFRESH_TOKEN_FAIL, { error });
      setSignedOut(true);
    });
    const token = currentSession?.getIdToken().getJwtToken();
    if (!token) {
      return undefined;
    }

    const decoded = jwtDecode(token);
    if (!decoded) {
      return undefined;
    }

    const { exp } = decoded;
    const expiryMilliseconds = (exp ?? 0) * 1000;
    const willExpireSoon = expiryMilliseconds - Date.now() < EXPIRY_THRESHOLD_MILLISECONDS;

    if (!willExpireSoon) {
      return token;
    }

    (await Auth.currentAuthenticatedUser({ bypassCache: true })) as CognitoUser;
    const newSession = await Auth.currentSession().catch((error) => {
      logger.warn(LogEvents.AUTH.REFRESH_TOKEN_FAIL, { error });
      setSignedOut(true);
    });

    const newToken = newSession?.getIdToken().getJwtToken();

    return newToken;
  };

  const fetch_ = async (url: string, options: RequestInit = {}): Promise<Response> => {
    try {
      const token = await getToken();

      if (!token) {
        logger.warn(LogEvents.AUTH.REFRESH_TOKEN_FAIL);
        setSignedOut(true);
        throw 'Unauthorized';
      }

      const headers = new Headers();
      headers.append('Authorization', `Bearer ${token}`);
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');

      const result = await fetch(url, { ...options, headers });

      if (!result.ok) {
        const errorBody = await result?.json?.();

        throw { ...errorBody, status: result.status };
      }

      return result;
    } catch (error) {
      captureException(error);

      throw error;
    }
  };

  return { fetch_, signedOut, setSignedOut };
};
