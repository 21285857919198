import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';

import { createEnum } from '@cloud-wave/neon-common-lib';

import Icon from '../Icon';
import Text from '../Text';
import Tooltip from '../atoms/Tooltip';

import './badge.scss';

export const BadgeTypes = createEnum(
  'NEUTRAL',
  'SECONDARY',
  'SUCCESS',
  'WARNING',
  'HOLD',
  'DANGER',
  'DEFAULT',
  'WHITE',
  'BLACK'
);
export type TBadgeType = keyof typeof BadgeTypes;

export type BadgeProps = {
  label?: string;
  icon?: IconDefinition;
  type?: TBadgeType;
  reverse?: boolean;
  tooltip?: string;
  bold?: boolean;
  className?: string;
  minWidth?: number;
  isTimer?: boolean;
  ariaLabel?: string;
};

export default function Badge({
  type = 'SUCCESS',
  reverse,
  icon,
  label,
  tooltip,
  className,
  bold,
  minWidth,
  isTimer,
  ariaLabel
}: BadgeProps) {
  const labelEl = (
    <Text bold={bold} medium={!bold} type="extraSmall" className={'badge__content'}>
      {label}
    </Text>
  );

  const badgeEl = (
    <div
      role={isTimer ? 'timer' : void 0}
      tabIndex={isTimer ? 0 : void 0}
      data-testid={`badge__${type}`}
      className={cx('badge', `badge__${type.toLowerCase()}`, className, { 'badge--reverse': reverse })}
      style={{ minWidth }}
    >
      {ariaLabel && (
        <span className="sr-only">
          {ariaLabel} {label}
        </span>
      )}
      {icon && <Icon icon={icon} size={15} className={'badge__content'} />}
      {label && labelEl}
    </div>
  );

  if (!tooltip) {
    return badgeEl;
  }

  return (
    <Tooltip ariaHidden enterDelay={1000} title={tooltip} placement="top">
      {badgeEl}
    </Tooltip>
  );
}
