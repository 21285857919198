import { ReactNode, useEffect, useMemo, useState } from 'react';

import _debounce from 'lodash.debounce';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useEmailUploadContext } from 'lib/common/contexts/EmailUploadContext';

import { LogEvents, logger } from 'lib/common/components/LoggerController';
import { useTagInputReducer } from 'lib/common/components/TagInput';
import EmailModal from 'lib/common/components/atoms/Email/EmailModal';
import { EmailModalTypes } from 'lib/common/components/atoms/Email/EmailModal';

import contactStates from 'lib/common/constants/contactStates';
import UploadedFileStatus from 'lib/common/constants/files/UploadedFileStatus';

import TEmailTask from 'lib/common/types/EmailTask';
import TaskContentTypes from 'lib/common/types/TaskContentTypes';
import ISendableEmailAddress from 'lib/common/types/email/SendableEmailAddress';
import connectGetter from 'lib/common/utils/connectGetter';
import getPreSendModal from 'lib/common/utils/email/getPreSendModal';
import toast from 'lib/common/utils/toast';

import getSendableEmailAddresses from './api/getSendableEmailAddresses';
import sendEmailRequest, { EmailSendTypes } from './api/sendEmail';

const EMPTY_CONTENT =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

const UPDATE_TASK_CONTENT_DEBOUNCE_MS = 500;

export default function useEmail(task: TEmailTask) {
  const {
    actions: { setTaskContent, removeDraftTask, hangupAgent }
  } = useContactContext();
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const {
    actions: { getFilesForContext, clearFiles }
  } = useEmailUploadContext();

  const { email, taskId, contact } = task;
  const savedEmailState = email || {};
  const { originalAttachments } = savedEmailState;
  const type = savedEmailState.type || EmailSendTypes.NEW;

  const [availableFromAddresses, setAvailableFromAddresses] = useState<ISendableEmailAddress[] | null>(null);
  const [fromAddressId, setFromAddressId] = useState<string | null>(savedEmailState.fromAddressId || null);

  const [subject, setSubject] = useState<string>(savedEmailState.subject || '');

  const [content, setContent] = useState<string>(savedEmailState.content || EMPTY_CONTENT);
  const [htmlContent, setHtmlContent] = useState('');

  const [to, setTo] = useTagInputReducer(savedEmailState.to);
  const [cc, setCc] = useTagInputReducer(savedEmailState.cc);
  const [bcc, setBcc] = useTagInputReducer(savedEmailState.bcc);

  const [preSendModalContent, setPreSendModalContent] = useState<ReactNode>(null);
  const [visibleModalType, setVisibleModalType] = useState<EmailModalTypes | null>(null);

  const onChangeEmailField = useMemo(
    () =>
      _debounce(({ subject, to, cc, bcc, content, fromAddressId }) => {
        setTaskContent(taskId, TaskContentTypes.EMAIL, { subject, to, cc, bcc, content, fromAddressId });
      }, UPDATE_TASK_CONTENT_DEBOUNCE_MS),
    []
  );

  useEffect(() => {
    onChangeEmailField({ subject, to, cc, bcc, content, fromAddressId });
  }, [subject, to, cc, bcc, content, fromAddressId]);

  const sendEmail = async ({ attachmentKeys }: { attachmentKeys: string[] }) => {
    if (!fromAddressId) {
      return Promise.reject();
    }

    const payload = {
      attachmentKeys,
      content: htmlContent,
      emailRuleId: fromAddressId,
      subject,
      to: to.tags.map(({ value }) => value),
      cc: cc.tags.map(({ value }) => value),
      bcc: bcc.tags.map(({ value }) => value),
      type
    };

    await sendEmailRequest(fetch_, config, {
      ...payload,
      originalContactId: type !== EmailSendTypes.NEW ? contact.contactId : void 0,
      emailContentKey: savedEmailState.metadata?.contentKey,
      originalAttachmentIds: (originalAttachments || []).map((attachment) => attachment.id)
    });

    // Clear local attachments references
    clearFiles(taskId);

    return true;
  };

  useEffect(() => {
    (async () => {
      const emails = await getSendableEmailAddresses(fetch_, config);

      const contactAttributes = connectGetter(contact, 'getAttributes') || {};

      const { NEONNOW_AVAILABLE_FROM_EMAILS, NEONNOW_DEFAULT_FROM_EMAIL } = contactAttributes;

      const availableEmailsCommaDelimited = NEONNOW_AVAILABLE_FROM_EMAILS?.value || '';
      const availableEmails = availableEmailsCommaDelimited.split(',').filter((email) => email.trim());
      const defaultFromEmail = NEONNOW_DEFAULT_FROM_EMAIL?.value || '';

      const listOfEmailsToDisplay = availableEmails.length
        ? emails.filter(({ email }) => availableEmails.includes(email))
        : emails;

      setAvailableFromAddresses(listOfEmailsToDisplay);

      const defaultEmail = listOfEmailsToDisplay.find(({ email }) => email === defaultFromEmail);

      if (defaultEmail) {
        return void setFromAddressId(defaultEmail.id);
      }

      if (listOfEmailsToDisplay.length === 1) {
        setFromAddressId(listOfEmailsToDisplay[0].id);
      }
    })();
  }, []);

  const onChangeContent = (content: string) => {
    setContent(content);

    if (!savedEmailState.originalEmailHtml) {
      return;
    }

    // Once the content callback has been called, the html should have been appended, so we can remove the reference to it
    setTaskContent(taskId, TaskContentTypes.EMAIL, { originalEmailHtml: void 0 });
  };

  const discardEmail = () => {
    logger.info(LogEvents.EMAIL.DRAFT_CREATED.CLOSE);

    clearFiles(taskId);

    if (type !== EmailSendTypes.NEW) {
      return void setTaskContent(taskId, TaskContentTypes.STATUS, contactStates.CONNECTED);
    }

    removeDraftTask(taskId);
  };

  const onRemoveOriginalAttachment = (originalId: number) => {
    setTaskContent(taskId, TaskContentTypes.EMAIL, {
      originalAttachments: originalAttachments?.filter(({ id }) => id !== originalId)
    });
  };

  const sendEmailWithPayload = async () => {
    const attachments = getFilesForContext(taskId);

    const uploadedAttachmentKeys = Object.entries(attachments)
      .filter(([_, uploadedFile]) => uploadedFile.status === UploadedFileStatus.COMPLETE)
      .map(([key]) => key);

    return sendEmail({
      attachmentKeys: uploadedAttachmentKeys
    });
  };

  const onSendEmailSuccess = async () => {
    toast('success', 'We sent that email successfully.', { autoClose: 3000, hideProgressBar: false });

    if (savedEmailState.type === EmailSendTypes.REPLY) {
      clearFiles(taskId);

      // If we fail to hang up agent, they shouldn't resend the email again, so take them back to the task
      // If it's successful they should go to ACW
      try {
        await hangupAgent(taskId);
      } catch {
        discardEmail();
      }

      return;
    }

    discardEmail();

    return Promise.resolve();
  };

  const onSendEmail = () => {
    const attachments = getFilesForContext(taskId);
    const preSendModal = getPreSendModal({ attachments, originalAttachments, subject, content: htmlContent });

    if (preSendModal) {
      setPreSendModalContent(preSendModal.content);
      setVisibleModalType(preSendModal.type);

      return Promise.resolve(false);
    }

    return sendEmailWithPayload();
  };

  const onCloseEmailModal = () => {
    setPreSendModalContent(null);
    setVisibleModalType(null);
  };

  return {
    state: {
      subject,
      to,
      cc,
      bcc,
      content,
      originalAttachments,
      fromAddressId,
      availableFromAddresses,
      originalEmailHtml: savedEmailState.originalEmailHtml
    },
    actions: {
      sendEmail,
      setSubject,
      setContent: onChangeContent,
      setHtmlContent,
      setFromAddressId,
      setTo,
      setCc,
      setBcc,
      discardEmail,
      onRemoveOriginalAttachment,
      onSendEmail,
      setVisibleModalType,
      onSendEmailSuccess
    },
    modal: (
      <EmailModal
        selectedTask={task}
        type={visibleModalType}
        onClose={onCloseEmailModal}
        modalPropOverrides={
          visibleModalType === EmailModalTypes.WARNING
            ? {
                onSave: sendEmailWithPayload,
                onSuccess: onSendEmailSuccess
              }
            : {}
        }
        customContent={preSendModalContent}
      />
    )
  };
}
