import { Transcript } from '@aws-sdk/client-connect-contact-lens';
import { faFileLines } from '@fortawesome/pro-duotone-svg-icons';
import cx from 'classnames';
import _capitalize from 'lodash.capitalize';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import Loader from 'lib/common/components/Loader';
import Text from 'lib/common/components/Text';

import VoiceTranscriptsFooter from './components/VoiceTranscriptsFooter';
import formatTranscriptTime from './utils/formatTranscriptTime';

import styles from './voice-transcripts.module.scss';

type TVoiceTranscriptsProps = {
  transcripts: Transcript[] | null;
  loading: boolean;
  nextToken?: string;
  onLoadMore: () => void;
};

const VoiceTranscripts = ({ transcripts, loading, nextToken, onLoadMore }: TVoiceTranscriptsProps) => {
  const { isSoftphone } = useLayout();

  if (!transcripts) {
    return (
      <EmptyPlaceholder
        icon={faFileLines}
        subText="If you believe this should be accessible, please reach out to your administrator for assistance."
        text="Transcript Not Available"
      />
    );
  }

  if (loading && !transcripts.length) {
    return <Loader relative small />;
  }

  if (!transcripts.length) {
    return (
      <EmptyPlaceholder
        icon={faFileLines}
        subText="There isn’t enough content in this voice interaction to show a transcript."
        text="Transcript Not Available"
      />
    );
  }

  return (
    <div className={cx(styles['voice-transcripts'], { [styles['voice-transcripts--softphone']]: isSoftphone })}>
      {transcripts.map((transcript) => {
        const isAgent = transcript.ParticipantRole === 'AGENT';
        const isSystem = transcript.ParticipantRole === 'SYSTEM';
        const isCustomer = transcript.ParticipantRole === 'CUSTOMER';

        return (
          <div
            key={transcript.Id}
            className={cx(styles['voice-transcripts__transcript'], {
              [styles['voice-transcripts__transcript--agent']]: isAgent,
              [styles['voice-transcripts__transcript--system']]: isSystem,
              [styles['voice-transcripts__transcript--customer']]: isCustomer
            })}
            data-testid={'voice-transcripts__transcript'}
          >
            <div className={styles['voice-transcripts__transcript__header']}>
              <Text className={styles['voice-transcripts__transcript__header__role']} ellipsis bold>
                {_capitalize(transcript.ParticipantRole)}
              </Text>
              <Text className={styles['voice-transcripts__transcript__header__time']}>
                {formatTranscriptTime(transcript.BeginOffsetMillis)}
              </Text>
            </div>
            <Text className={styles['voice-transcripts__transcript__content']}>{transcript.Content}</Text>
          </div>
        );
      })}
      <VoiceTranscriptsFooter loading={loading} handleLoadMoreTranscripts={onLoadMore} nextToken={nextToken} />
    </div>
  );
};

export default VoiceTranscripts;
