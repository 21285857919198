import { ChangeEvent, useState } from 'react';

import { faSearch, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { InputAdornment } from '@material-ui/core';

import { rateLimitMethod, useRateLimiter } from 'lib/common/hooks/useRateLimiter/useRateLimiter';

import ClickableIcon from 'lib/common/components/ClickableIcon/ClickableIcon';
import Icon from 'lib/common/components/Icon';
import { Input } from 'lib/common/components/Input';

import { TTableSearchProps } from '../../sharedTypes';

export const TableSearch = ({
  placeholder,
  onSearch = () => {},
  changeBehaviour = { limitingMethod: rateLimitMethod.debounce }
}: TTableSearchProps) => {
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useRateLimiter({ ...changeBehaviour, functionCall: onSearch });

  const onSearchChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value = '' } = event.target;
    setSearchValue(value);
    debounceSearch(value);
  };

  const endAdornment =
    searchValue !== '' ? (
      <ClickableIcon
        onClick={() => {
          setSearchValue('');
          onSearch('');
        }}
        icon={faXmark}
        size={15}
        tooltipPlacement="bottom"
        tooltip="Close"
      />
    ) : null;

  const SearchAdornment = () => (
    <InputAdornment position="start">
      <Icon icon={faSearch} color="midGrey" />
    </InputAdornment>
  );

  return (
    <Input
      placeholder={placeholder}
      value={searchValue}
      onChange={onSearchChange}
      InputProps={{
        startAdornment: <SearchAdornment />,
        endAdornment
      }}
    />
  );
};
