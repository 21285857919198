import { ICellRendererParams } from 'ag-grid-community';
import cx from 'classnames';

import Status from 'lib/common/components/Status';

import { TStatusType } from '../../../Status/Status';
import { TCellRenderer } from '../sharedTypes';

import styles from './cell-stlyes.module.scss';

type TStatusCellProps = { getStatusType: (value?: string) => TStatusType };

export const StatusCell: TCellRenderer<TStatusCellProps> = ({ getStatusType }) => {
  return ({ value, valueFormatted }: Partial<ICellRendererParams>) => {
    if (!value) {
      return <></>;
    }

    return <Status type={getStatusType(value)}>{valueFormatted || value}</Status>;
  };
};
