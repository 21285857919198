import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ICellRendererParams } from 'ag-grid-community';
import cx from 'classnames';

import Badge from 'lib/common/components/Badge';
import { TBadgeType } from 'lib/common/components/Badge/Badge';

import { TCellRenderer } from '../sharedTypes';

import styles from './cell-stlyes.module.scss';

type TBadgeCellProps = {
  getBadgeType?: (value?: string | null) => TBadgeType;
  getBadgeIcon?: (value?: string | null) => IconDefinition;
};

export const BadgeCell: TCellRenderer<TBadgeCellProps> = ({ getBadgeType, getBadgeIcon }) => {
  return ({ value, valueFormatted }: Partial<ICellRendererParams>) => {
    if (!value) {
      return <></>;
    }

    const badgeType = getBadgeType ? getBadgeType(value) : undefined;
    const badgeIcon = getBadgeIcon ? getBadgeIcon(value) : undefined;

    return (
      <Badge icon={badgeIcon} className={cx(styles['nn-cell__badge'])} type={badgeType} label={valueFormatted || ''} />
    );
  };
};
