import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  faArrowRightFromBracket,
  faCircleArrowLeft,
  faCircleQuestion,
  faFileLines,
  faSliders
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { SOFTPHONE_PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { OverlayType, useOverlayContext } from 'lib/common/contexts/OverlayContext/Context';
import { usePresenceContext } from 'lib/common/contexts/PresenceContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import usePresenceComponent from 'lib/common/hooks/usePresence/usePresenceComponent';

import Avatar from 'lib/common/components/Avatar';
import PopoverMenu from 'lib/common/components/PopoverMenu';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';
import Tooltip from 'lib/common/components/atoms/Tooltip';

import getUserName from 'lib/common/utils/getUserName';
import getStatusIconColour from 'lib/common/utils/presence/getStatusIconColour';

import COLOURS from 'css/export-vars.module.scss';

import './styles/user-profile.scss';

interface IUserProfileProps {
  // To be fixed as part of NEON-4280
  user: any;
  isReversedPopover?: boolean;
  statusBackgroundColor?: string;
  isLandingPage?: boolean;
}

const PROFILE_MENU_LEFT_OFFSET = 30;
const NO_PROFILE_MENU_LEFT_OFFSET = 0;
const PROFILE_MENU_OFFSET = 20;
const SOFTPHONE_PROFILE_MENU_OFFSET = 3;
const DESKTOP_PRESENCE_DROPDOWN_MAX_HEIGHT = '200px';

function getPopoverMenuLeftOffset({ isSoftphone, isReversedPopover }) {
  if (!isSoftphone) {
    return NO_PROFILE_MENU_LEFT_OFFSET;
  }

  if (isReversedPopover) {
    return -PROFILE_MENU_LEFT_OFFSET;
  }

  return PROFILE_MENU_LEFT_OFFSET;
}

const UserProfile = ({ isLandingPage, user, isReversedPopover, statusBackgroundColor }: IUserProfileProps) => {
  const { isSoftphone } = useLayout();
  const { signOut } = useAuthContext();
  const { config } = useConfigContext();
  const history = useHistory();
  const { agentPresence, agentNextState } = usePresenceContext();
  const { openOverlay } = useOverlayContext();
  const {
    state: { tasks }
  } = useContactContext();
  const [showPresenceOptions, setShowPresenceOptions] = useState(false);
  const openPreferences = () => openOverlay(OverlayType.PREFERENCES);

  const name = getUserName(user);

  const { anchorButton, presenceOptions, anchorPopoverMenuItem } = usePresenceComponent({
    name: isSoftphone ? name : void 0,
    onFinally: isSoftphone ? () => setShowPresenceOptions(false) : void 0
  });

  const presence = agentNextState ? agentNextState?.name : agentPresence;
  const mappedStatusColour = getStatusIconColour(presence);

  const statusColour = agentNextState
    ? `linear-gradient(90deg, ${COLOURS.danger} 52%, ${mappedStatusColour} 48%)`
    : COLOURS[mappedStatusColour];

  const avatar = (
    <Tooltip
      disableHoverListener={isLandingPage}
      enterDelay={500}
      title={isSoftphone ? presence : 'User Menu'}
      placement={isSoftphone ? 'right' : undefined}
      ariaHidden
    >
      <button aria-label={`${name} User Menu`} className="no-styles-button">
        <span className="user-profile__tooltip">
          <Avatar
            name={name}
            size={isSoftphone ? Avatar.Sizes.SMALL : void 0}
            statusColour={isSoftphone ? statusColour : void 0}
            statusBackgroundColor={statusBackgroundColor}
          />
        </span>
      </button>
    </Tooltip>
  );

  const status = (
    <div
      className={cx('user-profile__username-status-wrapper', {
        'user-profile__username-status-wrapper--softphone': isSoftphone
      })}
    >
      {!isSoftphone && <div className="user-profile__username">{name}</div>}
      <PopoverMenu
        closeOnClick
        anchor={anchorButton}
        maxHeight={!isSoftphone ? DESKTOP_PRESENCE_DROPDOWN_MAX_HEIGHT : void 0}
      >
        {presenceOptions}
      </PopoverMenu>
    </div>
  );

  const onNavigate =
    ({ link, external }: { link: string; external?: boolean }) =>
    () => {
      if (!external) {
        return void history.push(link);
      }

      window.open(link, '_blank')?.focus();
    };

  const downloadLogs = () => {
    connect.getLog()?.download();
  };

  return (
    <div className="user-profile__wrapper">
      <PopoverMenu
        disabled={isLandingPage}
        onClose={() => setShowPresenceOptions(false)}
        anchor={avatar}
        offset={isSoftphone ? SOFTPHONE_PROFILE_MENU_OFFSET : PROFILE_MENU_OFFSET}
        offsetLeft={getPopoverMenuLeftOffset({ isSoftphone, isReversedPopover })}
        reverse={false}
        closeOnClick
        childrenDepth={1}
      >
        {showPresenceOptions ? (
          <>
            <PopoverMenuItem
              autoFocus
              icon={faCircleArrowLeft}
              text="Back"
              onClick={() => setShowPresenceOptions(false)}
              onSuccess={() => {}}
            />
            <PopoverMenuItem.Separator />
            {presenceOptions}
          </>
        ) : (
          <>
            <>
              {isSoftphone &&
                anchorPopoverMenuItem(() => {
                  setShowPresenceOptions(true);
                })}
            </>
            {isSoftphone && <PopoverMenuItem.Separator />}

            <PopoverMenuItem
              onClick={isSoftphone ? onNavigate({ link: SOFTPHONE_PAGE_ROUTES.PREFERENCES }) : openPreferences}
              icon={faSliders}
              text="Preferences"
            />

            <PopoverMenuItem
              onClick={onNavigate({ link: config.BRAND.helpDocsUrl, external: true })}
              icon={faCircleQuestion}
              text="Help Docs"
            />
            <PopoverMenuItem onClick={downloadLogs} icon={faFileLines} text="Download Logs" />
            <PopoverMenuItem.Separator />
            <PopoverMenuItem
              disabled={Boolean(tasks.length)}
              tooltipId={tasks.length ? 'sign-out' : void 0}
              tooltip="You need to clear all of your active tasks before you can sign out."
              tooltipDelay={0}
              onClick={!tasks.length ? () => signOut() : void 0}
              text="Sign Out"
              icon={faArrowRightFromBracket}
            />
          </>
        )}
      </PopoverMenu>
      {!isSoftphone && status}
    </div>
  );
};

export default UserProfile;
