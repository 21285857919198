import { useEffect, useState } from 'react';

import { Transcript } from '@aws-sdk/client-connect-contact-lens';

import { PostCallAnalysisAction } from '@cloud-wave/neon-common-lib';

import getPostCallAnalysis from 'lib/common/components/molecules/ACW/components/ACWOutcome/api/getPostCallAnalysis';

import { TConfig } from 'lib/common/types/Config';
import TFetch from 'lib/common/types/Fetch';

export default function useVoiceTranscripts({
  config,
  fetch_,
  contactId,
  canViewPostCallTranscripts
}: {
  config: TConfig;
  fetch_: TFetch;
  contactId: string;
  canViewPostCallTranscripts: boolean;
}) {
  const [transcripts, setTranscripts] = useState<Transcript[] | null>([]);
  const [nextToken, setNextToken] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const getCallTranscripts = async () => {
    try {
      setLoading(true);
      const postCallData = await getPostCallAnalysis({
        config,
        fetch_,
        contactId,
        nextToken,
        action: PostCallAnalysisAction.TRANSCRIPTS
      });

      setTranscripts((prevTranscripts) => [
        ...(prevTranscripts || []),
        ...('transcripts' in postCallData ? postCallData?.transcripts : [])
      ]);
      setNextToken('nextToken' in postCallData ? postCallData?.nextToken : void 0);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching transcripts', error);
      setTranscripts(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    setTranscripts([]);

    if (!canViewPostCallTranscripts) {
      return;
    }

    (async () => {
      await getCallTranscripts();
    })();
  }, [contactId]);

  return { transcripts, loading, getCallTranscripts, nextToken };
}
