import { useRef } from 'react';

import { TUserWithConnect } from '@cloud-wave/neon-common-lib';

import { TAnyTask } from 'lib/common/contexts/ContactContext';

import ChatPlatformBadge from 'lib/common/components/ChatPlatformBadge';
import Loader from 'lib/common/components/Loader';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import { getTaskDisplayProps } from 'lib/common/constants/taskStates';
import TASK_CONTENT_TYPES from 'lib/common/constants/tasks/taskContentTypes';

import TChatTask from 'lib/common/types/ChatTask';
import TEmailTask from 'lib/common/types/EmailTask';
import TOutboundPreviewTask from 'lib/common/types/OutboundPreviewTask';
import getTaskContentType from 'lib/common/utils/getTaskContentType';

import CreateEmail from '../../CreateEmail';
import AfterContactWork from './AfterContactWork';
import AgentTask from './AgentTask';
import ChatTask from './Chat';
import ConnectingTask from './ConnectingTask';
import Email from './Email';
import NeglectedTask from './NeglectedTask';
import OutboundPreviewTask from './OutboundPreviewTask';
import TaskDetailHeader from './TaskDetailHeader';
import TimedTask from './TimedTask';

import '../styles/task-detail.scss';

export default function TaskDetail({
  selectedTask,
  setCreateAgentTaskOpen,
  setCreateTaskDefaults,
  user
}: {
  selectedTask?: TAnyTask;
  setCreateAgentTaskOpen: (boolean) => void;
  setCreateTaskDefaults: (any) => void;
  user: TUserWithConnect;
}) {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  if (!selectedTask) {
    return null;
  }

  const { status, type } = selectedTask;
  const title = getTaskDisplayProps(selectedTask).subtitle;

  if (!title) {
    return <Loader small />;
  }

  const getTaskContent = () => {
    const taskContentType = getTaskContentType({ selectedTask });

    if (taskContentType === TASK_CONTENT_TYPES.AFTER_CONTACT_WORK) {
      return (
        <AfterContactWork
          {...selectedTask}
          setCreateAgentTaskOpen={setCreateAgentTaskOpen}
          setCreateTaskDefaults={setCreateTaskDefaults}
          user={user}
        />
      );
    }

    if (taskContentType === TASK_CONTENT_TYPES.CONNECTING_TASK) {
      return <ConnectingTask {...selectedTask} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.NEGLECTED_TASK) {
      return <NeglectedTask selectedTask={selectedTask} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.EMAIL_TASK && status === CONTACT_STATES.CONNECTED) {
      return <Email {...(selectedTask as TEmailTask)} key={selectedTask.taskId} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.EMAIL_TASK && status === CONTACT_STATES.DRAFT) {
      return <CreateEmail selectedTask={selectedTask} key={selectedTask.taskId} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.OUTBOUND_PREVIEW_TASK) {
      return <OutboundPreviewTask {...(selectedTask as TOutboundPreviewTask)} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.AGENT_TASK) {
      return <AgentTask {...selectedTask} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.CHAT_TASK) {
      return (
        <ChatTask scrollContainerRef={scrollContainerRef} {...(selectedTask as TChatTask)} key={selectedTask.taskId} />
      );
    }

    if (taskContentType === TASK_CONTENT_TYPES.TIMED_TASK) {
      return <TimedTask {...selectedTask} user={user} />;
    }

    return null;
  };

  const taskContent = getTaskContent();
  const isConnectedTask = type === CONTACT_TYPES.TASK && status === CONTACT_STATES.CONNECTED;
  const isConnectedEmail = type === CONTACT_TYPES.EMAIL && status === CONTACT_STATES.CONNECTED;
  const isChat = type === CONTACT_TYPES.CHAT;

  if (!taskContent) {
    return null;
  }

  return (
    <div className="task-detail" ref={scrollContainerRef}>
      <div className="task-header-container">
        {!isConnectedTask && !isConnectedEmail && <TaskDetailHeader>{title}</TaskDetailHeader>}
        {isChat && <ChatPlatformBadge task={selectedTask as TChatTask} />}
      </div>
      {taskContent}
    </div>
  );
}
