import { PostCallAnalysisAction, TPostCallAnalysisAction } from '@cloud-wave/neon-common-lib';

import type { TConfig } from 'lib/common/types/Config';
import { TPostCallAnalysis } from 'lib/common/types/PostCallAnalysis';

interface IArgs {
  config: TConfig;
  fetch_: (url: string, options: any) => Promise<any>;
  contactId: string;
  action: TPostCallAnalysisAction;
  nextToken?: string;
}

export default async function getPostCallAnalysis({
  config,
  fetch_,
  contactId,
  action,
  nextToken
}: IArgs): Promise<TPostCallAnalysis> {
  const endpoint = `${config.AGENT_SERVICE_V2_URL}/contact/${contactId}/post-call-analysis?action=${action}${
    nextToken ? `&nextToken=${encodeURIComponent(nextToken)}` : ''
  }`;

  const response = await fetch_(endpoint, {
    method: 'GET'
  });

  const analytics = (await response.json()).data;

  if (action === PostCallAnalysisAction.SUMMARY) {
    return {
      summary: analytics.summary
    };
  }

  return {
    transcripts: analytics.transcripts || [],
    nextToken: analytics.nextToken
  };
}
