import { TAttachment } from 'lib/common/types/email/TAttachment';
import getAttachmentCid from 'lib/common/utils/email/getAttachmentCid';
import isInlineImage from 'lib/common/utils/email/isInlineImage';

// Images are added to the email HTML content in the format <img src="cid:attachmentId" />
const IMAGE_HTML_REGEX = /<img[^>]+src=["']cid:([^"']+)["']/gi;

function generateRegexMatcherForAttachment({ filename, cid }: { filename: string; cid: string }) {
  // Escape special regex characters in filename and cid
  const escapedFilename = filename.replace(/[.+?^${}()|[]]/g, '$&');
  const escapedCid = cid.replace(/[.+?^${}()|[]]/g, '$&');

  /**
   •	Regex pattern to match different cid and image formats (ignoring case on prefix and spaces around the filename and cid):
   •	[cid:CID_VALUE]
   •	[image:FILENAME]
   •	[FILENAME]
   */
  return new RegExp(
    `\\[\\s*(?:image:\\s*${escapedFilename}|cid:\\s*${escapedCid}|${escapedFilename})\\s*\\]`,
    'gi' // Case-insensitive and global matching
  );
}

export default function replaceImageTextWithImages(
  attachments: TAttachment[],
  htmlContent?: string,
  textContent?: string
) {
  const inlineImageAttachments = attachments.filter(isInlineImage);

  if (!inlineImageAttachments.length || !textContent || !htmlContent) {
    return textContent;
  }

  // Extract the attachment IDs in the order they appear in the HTML content
  const attachmentIdsInOrder = Array.from(htmlContent.matchAll(IMAGE_HTML_REGEX), (match) => match[1]);

  // Map the attachment IDs to the corresponding attachment objects
  const orderedImageAttachments = attachmentIdsInOrder
    .map((cid) =>
      // Use the CID regex to remove the angle brackets from the attachment ID, that are not present in the HTML
      inlineImageAttachments.find((attachment) => getAttachmentCid(attachment) === cid)
    )
    .filter(Boolean) as TAttachment[];

  return orderedImageAttachments.reduce((contentWithImages, imageAttachment) => {
    const matcherRegex = generateRegexMatcherForAttachment({
      filename: imageAttachment.filename,
      cid: getAttachmentCid(imageAttachment) || ''
    });

    // Get the image match for the current attachment in the list. Check the filename also matches, skip if it doesn't and return a broken image
    return contentWithImages.replace(
      matcherRegex,
      `<img src="data:${imageAttachment.mimeType};base64,${imageAttachment.base64}" />`
    );
  }, textContent);
}
