import VARS from 'css/export-vars.module.scss';

interface TStyles {
  isSoftphone?: boolean;
  small?: boolean;
}

const SMALL_HEIGHT = 35;
const LARGE_HEIGHT = 45;

export const getDefaultStyles = ({ isSoftphone, small }: TStyles) => ({
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    borderColor: `${VARS.midGrey} !important`,
    minHeight: `${isSoftphone ? SMALL_HEIGHT : LARGE_HEIGHT}px !important`,
    opacity: isDisabled ? 0.6 : void 0,
    backgroundColor: VARS.white,

    '&:hover': {
      borderColor: `${isFocused ? VARS.primary : VARS.darkGrey} !important`
    },

    ...(isFocused
      ? {
          borderColor: `${VARS.primary} !important`,
          boxShadow: 'none !important'
        }
      : {})
  }),
  valueContainer: (styles) => ({
    ...styles,
    fontSize: isSoftphone ? VARS.fontSizeSmall : VARS.fontSizeNormal,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    fontSize: isSoftphone ? VARS.fontSizeSmall : VARS.fontSizeNormal,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: VARS.black,
    width: 'calc(100% - 20px)',
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 4,
    ...(isFocused && !isSelected ? { backgroundColor: VARS.lightGrey, cursor: 'pointer' } : {})
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  placeholder: (styles) => ({
    ...styles,
    color: VARS.darkGrey,
    ...(small
      ? {
          position: 'static',
          transform: 'none'
        }
      : {})
  }),
  singleValue: small
    ? (provided) => ({
        ...provided,
        position: 'static',
        transform: 'none'
      })
    : undefined,
  // Scroll bar stylings
  menuList: (base) => ({
    ...base,

    paddingTop: 10,
    paddingBottom: 10,

    '::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    // Track
    '::-webkit-scrollbar-track': {
      background: VARS.background
    },

    // Handle
    '::-webkit-scrollbar-thumb': {
      background: VARS.midGrey,
      borderRadius: '5px'
    },

    // Handle on hover
    '::-webkit-scrollbar-thumb:hover': {
      background: VARS.darkGrey
    }
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: VARS.midGrey
  })
});
