import { ChangeEvent, ReactNode, useRef } from 'react';

import { faPaperclip, faUpload } from '@fortawesome/pro-regular-svg-icons';

import { useEmailUploadContext } from 'lib/common/contexts/EmailUploadContext';
import { IFileUploadMetadata } from 'lib/common/contexts/EmailUploadContext';

import Button from 'lib/common/components/Button';
import ClickableIcon from 'lib/common/components/ClickableIcon';

import UploadedFileType from 'lib/common/constants/files/UploadedFileType';

import toast from 'lib/common/utils/toast';

import DropZone from './components/DropZone';
import validateFiles from './utils/validateFiles';

interface IDropZone {
  children?: ReactNode;
  type: UploadFilesTypesEmail.DROP_ZONE;
}

interface IUploadButton {
  children?: never;
  type: UploadFilesTypesEmail.BUTTON | UploadFilesTypesEmail.ICON;
}

export enum UploadFilesTypesEmail {
  BUTTON = 'BUTTON',
  ICON = 'ICON',
  DROP_ZONE = 'DROP_ZONE'
}

export default function UploadFilesEmail({
  onUploadFiles,
  onStartFileUpload,
  type,
  children,
  className,
  contextId,
  uploadedFileType,
  baseFileSizeTotal
}: {
  className?: string;
  contextId: string;
  onUploadFiles?: (files: IFileUploadMetadata[]) => void;
  onStartFileUpload?: () => void;
  uploadedFileType: UploadedFileType;
  helpLink?: string;
  baseFileSizeTotal?: number;
} & (IUploadButton | IDropZone)) {
  const {
    actions: { getFilesForContext, uploadFiles }
  } = useEmailUploadContext();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileArray = async (files: File[]) => {
    const validationError = validateFiles(files, getFilesForContext(contextId), baseFileSizeTotal);

    if (validationError) {
      return void toast('error', validationError);
    }

    onStartFileUpload?.();

    const fileUploadMetadata: IFileUploadMetadata[] = await uploadFiles(uploadedFileType, files, contextId);

    onUploadFiles?.(fileUploadMetadata);
  };

  const onChangeFiles = async (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files }
    } = event;

    if (!files || !files.length) {
      return;
    }

    const fileArray = Array.from(files);

    // Reset the file input so we can select the same file again
    event.target.value = '';

    return handleFileArray(fileArray);
  };

  return (
    <>
      {type === UploadFilesTypesEmail.DROP_ZONE ? (
        <DropZone className={className} handleFileArray={handleFileArray}>
          {children}
        </DropZone>
      ) : (
        <>
          {type === UploadFilesTypesEmail.BUTTON && (
            <Button icon={faUpload} onClick={onClick} className={className} data-testid="upload-files-button">
              Upload Attachments
            </Button>
          )}
          {type === UploadFilesTypesEmail.ICON && (
            <ClickableIcon
              className={className}
              icon={faPaperclip}
              onClick={onClick}
              data-testid="upload-files-button"
              tooltip="Attach Files"
              size={17}
            />
          )}
          <input
            type="file"
            id="upload-files"
            ref={fileInputRef}
            className="hide"
            onChange={onChangeFiles}
            multiple
            data-testid="upload-files"
            aria-label="Upload files"
          />
        </>
      )}
    </>
  );
}
