/*
 * contains reused fetch functions with validation
 *
 * calling context should handle errors
 * */
import { stringifyUrl } from 'query-string';

import { IFetch } from 'lib/core/context/AuthProvider/Context';

import { TCRUDProps, TEntityActionUrls, TQueryParams } from '../../CRUDTableTypes';
import { buildRawClientUrls } from './buildRawClientUrls';

export class RawCrudClient {
  private fetch: IFetch;
  private entityActionUrl: TEntityActionUrls;
  private entitySingular: string;
  private entityPlural: string;

  private hasExpectedKey(data: object, key: string) {
    const objectKeys = Object.keys(data);

    return objectKeys.includes(key);
  }

  constructor({ fetch, CRUDProps }: { fetch: IFetch; CRUDProps: TCRUDProps }) {
    const { entitySingular, entityPlural } = CRUDProps;

    const { qualifiedEntities, qualifiedEntityWithId, qualifiedEntity } = buildRawClientUrls(CRUDProps);

    // in the future we can also have our bulk actions listed here for delete etc
    this.fetch = fetch;
    this.entitySingular = entitySingular;
    this.entityPlural = entityPlural;
    this.entityActionUrl = {
      create: qualifiedEntity,
      read: qualifiedEntityWithId,
      list: qualifiedEntities,
      update: qualifiedEntityWithId,
      delete: qualifiedEntityWithId
    };
  }

  public create() {}

  public read() {}

  public async list({ queryParams }: { queryParams?: TQueryParams }) {
    const queryUrl = stringifyUrl({
      url: this.entityActionUrl.list,
      query: queryParams
    });

    const response = await (await this.fetch(queryUrl, { method: 'GET' })).json();
    // will have received something, but we don't currently know if it's as expected with the correct internal data
    const { data }: any = response;
    const { count } = data;

    // if the server returned that some entities exist, but they aren't named correctly, throw error for context to handle
    if (count > 0 && !this.hasExpectedKey(data, this.entityPlural)) {
      throw new Error(`list entities expected data to be set on response.data.${this.entityPlural}`);
    }

    return count ? { serverEntities: data?.[this.entityPlural], count } : { serverEntities: [], count: 0 };
  }

  public update() {}

  public async delete({ queryParams, id }: { queryParams?: TQueryParams; id: string }) {
    const queryUrl = stringifyUrl({
      url: `${this.entityActionUrl.delete}${id}`,
      query: queryParams
    });

    await this.fetch(queryUrl, { method: 'DELETE' });
  }
}
