import { ComponentType } from 'react';

import { TUserWithConnect } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

/**
 * This is used for pages/components that are supported in both desktop & isolated modes. We need to change
 * the auth context based on the mode and inject the correct props in to the page. User is optional based
 * on whether the child page/component needs it.
 *
 * This component is not required for components that are desktop/softphone only. They can use the standard
 * auth context.
 */
export default function WithApiContexts({
  component: Component,
  user,
  props
}: {
  component: ComponentType<any>;
  user?: TUserWithConnect | null;
  props?: any;
}) {
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();

  return <Component user={user} fetch={fetch_} config={config} {...(props || {})} />;
}
