import { Dispatch, SetStateAction } from 'react';

import { translate } from '@cloud-wave/neon-common-lib/app';

import { TQueryParams } from '../../../common/components/Table/Implementations/CRUDTable/CRUDTableTypes';
import Checkbox from '../../../common/components/atoms/Checkbox';

export const DeleteUserModalContent = ({
  deleteParamState: [deleteParams, setDeleteParams]
}: {
  deleteParamState: [TQueryParams, Dispatch<SetStateAction<TQueryParams>>];
}) => {
  const { deleteConnectUser } = deleteParams as { deleteConnectUser: false };

  const onChange = (value: boolean) => {
    setDeleteParams({ deleteConnectUser: value });
  };

  return (
    <Checkbox
      initialValue={deleteConnectUser}
      onChange={onChange}
      label={'Also remove this user from Amazon Connect'}
      subtitle={`Removes the user from your contact ${translate(
        'centre'
      )} while keeping their Amazon Connect account active. They will still be able to sign in, but will encounter an error page.`}
    />
  );
};
