import { ChatPlatform } from '@cloud-wave/neon-common-lib';

import { ValueOf } from 'lib/core/types';

import getContactChatSession from 'lib/common/contexts/ContactContext/utils/getContactChatSession';
import taskBuilder from 'lib/common/contexts/ContactContext/utils/taskBuilder/index';
import { THasPermission } from 'lib/common/contexts/PermissionsContext/Provider';

import CONTACT_STATES from 'lib/common/constants/contactStates';

import ChatTask from 'lib/common/types/ChatTask';
import getContactAttributes from 'lib/common/utils/getContactAttributes';

import { getCanUseAttachments } from './getCanUseAttachments';
import { getCanUseRichText } from './getCanUseRichText';

export default async function newChatTask({
  contact,
  hasPermission,
  status
}: {
  contact: connect.Contact;
  hasPermission: THasPermission;
  status?: ValueOf<typeof CONTACT_STATES>;
}): Promise<ChatTask | null> {
  const chatSession = (await getContactChatSession(contact).catch(() => undefined)) as connect.AgentChatSession;
  const newTask = taskBuilder.newBaseTask(contact, status);
  if (!newTask) {
    return null;
  }

  const neonChatPlatform =
    getContactAttributes(
      {
        task: newTask
      },
      'neonChatPlatform'
    ).neonChatPlatform || ChatPlatform.DEFAULT;
  const isConnectWebChat = neonChatPlatform === ChatPlatform.DEFAULT;
  const canUseAttachments = getCanUseAttachments(isConnectWebChat, hasPermission, newTask);
  const canUseRichText = getCanUseRichText(isConnectWebChat, hasPermission);

  return {
    ...newTask,
    chatSession,
    messages: [],
    neonChatPlatform,
    canUseAttachments,
    canUseRichText,
    customerTyping: false,
    unreadMessage: false,
    chatDraftMessage: {
      content: '',
      attachment: null
    }
  };
}
