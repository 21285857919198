import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { P, match } from 'ts-pattern';

import { IModule } from '@cloud-wave/neon-common-lib';
import {
  PAGE_CONFIGURATIONS,
  PAGE_ROUTES,
  SOFTPHONE_PAGE_CONFIGURATIONS,
  SOFTPHONE_PAGE_ROUTES
} from '@cloud-wave/neon-common-lib/app';

import { useModulesContext } from 'lib/common/contexts/ModulesContext';

import { PageContentContainer, TPageType } from '../CoreLayout/types/pages';

type RouteKey = keyof typeof PAGE_ROUTES;
type TUsePagePropsReturn = { className: string; title?: string; subtitle?: string; pageContentContainer: TPageType };

const modulesReduceTransformer = (result: object, pageModule: IModule) => {
  match(pageModule)
    .with({ pathName: P.string }, (pageModule) => ({
      [pageModule.pathName]: {
        title: pageModule.type === 'PAGE' ? pageModule.name : '',
        pageContentContainer: PageContentContainer.FREE_FORM
      }
    }))
    .otherwise(() => ({}));

  return { ...result, ...pageModule };
};

// Constants for regex patterns
const DYNAMIC_ROUTE_SEGMENT = /:\w+/g;
const DYNAMIC_ROUTE_SEGMENT_REPLACEMENT = '[^/]+';
const SLASH = /\//g;
const ESCAPED_SLASH = '\\/';

/**
 * Matches the given pathname against defined routes.
 */
const matchRoute = (pathname: string): RouteKey | null => {
  const routeEntries = [...Object.entries(PAGE_ROUTES), ...Object.entries(SOFTPHONE_PAGE_ROUTES)];

  const matchedEntry = routeEntries.find(([_, route]) => {
    const dynamicRoutePattern = route.replace(DYNAMIC_ROUTE_SEGMENT, DYNAMIC_ROUTE_SEGMENT_REPLACEMENT);
    const escapedPattern = dynamicRoutePattern.replace(SLASH, ESCAPED_SLASH);

    return new RegExp(`^${escapedPattern}$`).test(pathname);
  });

  return (matchedEntry?.at(0) as RouteKey) ?? null;
};

export default function usePageProps() {
  const { pathname } = useLocation();
  const {
    state: { pageModules }
  } = useModulesContext();

  const isReportPage = pathname.includes('reports/');

  //Report pages and non-titled pages must style their own layout
  const pageContainerFallback =
    !PAGE_CONFIGURATIONS[pathname] || isReportPage ? PageContentContainer.FREE_FORM : PageContentContainer.CONTROLLED;

  const pageModulesNavOptions = useMemo(() => {
    return pageModules ? pageModules?.reduce(modulesReduceTransformer, {}) : {};
  }, [pageModules]);

  // I don't think use memo makes sense here with how it's being used every time the path is changed we recalc the settings
  // Would make more sense only to recalculate when pageModulesNavOptions changes, no?
  const {
    title = '',
    pageContentContainer = pageContainerFallback,
    subtitle = ''
  }: TUsePagePropsReturn = useMemo(() => {
    const matchingPageRoute = matchRoute(pathname) || '';

    return (
      PAGE_CONFIGURATIONS[PAGE_ROUTES[matchingPageRoute]] ||
      SOFTPHONE_PAGE_CONFIGURATIONS[SOFTPHONE_PAGE_ROUTES[matchingPageRoute]] ||
      pageModulesNavOptions[pathname] ||
      {}
    );
  }, [pathname]);

  return { title, subtitle, pageContentContainer };
}
