import React from 'react';

import { faSearch } from '@fortawesome/pro-duotone-svg-icons';
import { CustomNoRowsOverlayProps } from 'ag-grid-react';

import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import Text from 'lib/common/components/Text';

/**
 * Allows us to show the empty placeholder when row data is empty
 * This is based off ag-grids documentation and does Leave much to be desired, but trying not to re-invent the wheel
 * Should require minimal interaction by any developer
 *
 * only used for search as the table component renders the non-search empty placeholder
 * @link https://www.ag-grid.com/javascript-data-grid/overlays/#custom-no-rows-overlay
 * */
export const NoRowsSearchEmptyPlaceholder = ({
  noRowsMessageFunc
}: CustomNoRowsOverlayProps & { noRowsMessageFunc: () => string }) => {
  const searchText = noRowsMessageFunc();
  return (
    <div role="presentation" className="ag-overlay-center">
      <EmptyPlaceholder
        text="We couldn’t find anything that matches"
        subText={
          <Text color="darkGrey">
            There aren’t any items that match <Text.Bold>{searchText}</Text.Bold>.
          </Text>
        }
        icon={faSearch}
      />
    </div>
  );
};
