import { CSSProperties } from 'react';

import COLOURS from 'css/export-vars.module.scss';

export const reactCodeInputStyle = ({
  isSoftphone,
  isSmallSoftphone
}: {
  isSoftphone: boolean;
  isSmallSoftphone: boolean;
}): CSSProperties => ({
  borderRadius: 12,
  height: isSmallSoftphone ? 50 : 70,
  maxWidth: 'calc(100% / 6 - 10px + 10px / 5)',
  border: '1px solid lightgrey',
  fontSize: isSoftphone ? '23px' : '33px',
  color: 'black',
  backgroundColor: 'white',
  outlineColor: COLOURS.primary,
  textAlign: 'center'
});
export const reactCodeInvalidStyle: CSSProperties = {
  borderColor: 'red'
};
