import { TAgentPreference } from '@cloud-wave/neon-common-lib';
import { AgentEmailSignatureInsertOn } from '@cloud-wave/neon-common-lib/common';

export default {
  agentId: '',
  tenantId: '',
  emailSignature: '',
  emailSignatureInsetOn: AgentEmailSignatureInsertOn.NEVER,
  createdAt: '',
  updatedAt: ''
} as TAgentPreference; //TODO switch as to satisfies once implementing ticket for address is complete
