import { useEffect } from 'react';

import { faPaperclipVertical } from '@fortawesome/pro-duotone-svg-icons';
import cx from 'classnames';

import { useEmailUploadContext } from 'lib/common/contexts/EmailUploadContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import Email from 'lib/common/components/atoms/Email';

import UploadedFileType from 'lib/common/constants/files/UploadedFileType';

import { TAttachment } from 'lib/common/types/email/TAttachment';

import FileCardEmail from './components/FileCardEmail';

import styles from './files-email.module.scss';

interface IFilesProps {
  contextId: string;
  uploadedFileType: UploadedFileType;
  className?: string;
  originalAttachments?: TAttachment[];
  onRemoveOriginalAttachment: (id: number) => void;
}

const SCROLL_SELECTOR_ID = 'files-scroll-to';

export default function FilesEmail({
  contextId,
  uploadedFileType,
  className,
  originalAttachments,
  onRemoveOriginalAttachment
}: IFilesProps) {
  const {
    actions: { deleteFile, getFilesForContext }
  } = useEmailUploadContext();

  const { isSoftphone } = useLayout();

  const files = getFilesForContext(contextId);

  const isEmpty = !Object.values(files).length && !originalAttachments?.length;

  const onDelete = (fileKey: string) => async () => {
    await deleteFile(uploadedFileType, fileKey);
  };

  useEffect(() => {
    document.querySelector(`#${SCROLL_SELECTOR_ID}`)?.scrollIntoView?.({ behavior: 'smooth' });
  }, [Object.keys(files).length]);

  return (
    <div
      className={cx(styles.files, className, {
        [styles['files--softphone']]: isSoftphone,
        [styles['files--empty']]: isEmpty
      })}
    >
      {isEmpty && isSoftphone && (
        <EmptyPlaceholder
          icon={faPaperclipVertical}
          text="There Aren't Any Attachments"
          subText="Try uploading one or more files."
        />
      )}
      {originalAttachments?.map((attachment) => (
        <Email.OriginalAttachment
          attachment={attachment}
          key={attachment.id}
          onRemove={onRemoveOriginalAttachment}
          className={cx({ 'full-width': isSoftphone })}
        />
      ))}
      {Object.entries(files).map(([fileKey, file]) => (
        <FileCardEmail
          id={fileKey}
          file={file}
          key={fileKey}
          onDelete={onDelete(fileKey)}
          className={isSoftphone ? styles['files--softphone__file'] : void 0}
        />
      ))}
      <span id={SCROLL_SELECTOR_ID} />
    </div>
  );
}
