import {
  faClockNine as fadClockNine,
  faCookie as fadCookie,
  faHeadset as fadHeadset,
  faKey as fadKey,
  faMessage as fadMessage,
  faScreenUsers as fadScreenUsers,
  faShield as fadShield,
  faSmile as fadSmile
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faClockNine as farClockNine,
  faCookie as farCookie,
  faHeadset as farHeadset,
  faKey as farKey,
  faMessage as farMessage,
  faScreenUsers as farScreenUsers,
  faShield as farShield,
  faSmile as farSmile
} from '@fortawesome/pro-regular-svg-icons';

import { translate } from '@cloud-wave/neon-common-lib/app';

import Text from 'lib/common/components/Text';

import { HELP_LINKS } from 'lib/common/constants/help';

export const AUTH_ERROR_HELP_ITEMS = (config) => [
  {
    icon: fadSmile,
    smallIcon: farSmile,
    title: 'Check Your Sign In',
    text: (
      <>
        Make sure you’re signed into Amazon Connect with the correct account. If no email is shown above, your admin may
        not have set you up yet.
      </>
    )
  },
  {
    icon: fadHeadset,
    smallIcon: farHeadset,
    title: `Check Your Contact ${translate('Centre')}`,
    text: (
      <>
        Confirm your user access and permissions for this contact {translate('centre')}. Ensure the web address above is
        correct.
      </>
    )
  },
  {
    icon: fadCookie,
    smallIcon: farCookie,
    title: 'Clear Data & Enable Cookies',
    text: (
      <>
        Clear site data and enable cookies for <Text.Bold>{window.location.host}</Text.Bold>.{' '}
        <Text type="link" href={`${config.BRAND.helpDocsUrl}${HELP_LINKS.REQUIREMENTS.STORAGE_ACCESS}`}>
          Learn More.
        </Text>
      </>
    )
  },
  {
    icon: fadMessage,
    smallIcon: farMessage,
    title: 'Get In Touch',
    text: <>Contact your Admin for further assistance.</>
  }
];

export const DEVICE_FORGOTTEN_HELP_ITEMS = [
  {
    icon: fadShield,
    smallIcon: farShield,
    title: 'Verification Code',
    text: (
      <>
        If multi-factor authentication (MFA) is enabled, you’ll need to <Text.Bold>enter a verification code</Text.Bold>{' '}
        when signing back in.
      </>
    )
  },
  {
    icon: fadKey,
    smallIcon: farKey,
    title: 'Staying Signed In',
    text: (
      <>
        If MFA is enabled and you want to skip the verification code when signing in, keep this device remembered by{' '}
        <Text.Bold>not choosing</Text.Bold> “Forget Device” when you sign out.
      </>
    )
  }
];

export const SIGNED_OUT_HELP_ITEMS = ({ forgetDevice, signIn }: { forgetDevice: () => void; signIn: () => void }) => [
  {
    icon: fadClockNine,
    smallIcon: farClockNine,
    title: 'Signing Back In',
    text: 'If multi-factor authentication (MFA) is enabled, you won’t need a verification code on this device when you next sign in. Just click below or reopen the app anytime.',
    callToAction: {
      text: 'Sign In',
      onClick: signIn
    }
  },
  {
    icon: fadScreenUsers,
    smallIcon: farScreenUsers,
    title: 'Sharing This Device?',
    text: 'If you share this device with others, we recommend forgetting this device to keep your account secure.',
    callToAction: {
      text: 'Forget Device',
      onClick: forgetDevice
    }
  }
];

export const AUTH_FAIL_SIGNED_OUT_HELP_ITEMS = ({ signIn }: { signIn: () => void }) => [
  {
    icon: fadClockNine,
    smallIcon: farClockNine,
    title: 'Signing Back In',
    text: 'If multi-factor authentication (MFA) is enabled, you won’t need a verification code on this device when you next sign in. Just click below or reopen the app anytime.',
    callToAction: {
      text: 'Sign In',
      onClick: signIn
    }
  }
];
