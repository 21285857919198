import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ICellRendererParams } from 'ag-grid-community';

import Icon from 'lib/common/components/Icon';

import { IconSizeConstraints } from '../../../Icon/Icon';
import { TCellRenderer } from '../sharedTypes';

type TIconAndTextCellProps = { getIconForValue: (value?: string | null) => IconDefinition };

export const IconAndTextCell: TCellRenderer<TIconAndTextCellProps> = ({ getIconForValue }) => {
  return ({ valueFormatted }: Partial<ICellRendererParams>) => {
    if (!valueFormatted) {
      return <></>;
    }
    const icon = getIconForValue(valueFormatted);
    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}
        data-testId={`cell-${valueFormatted}`}
      >
        <Icon
          icon={icon}
          color="darkGrey"
          size={15}
          onlyConstrain={IconSizeConstraints.WIDTH}
          title={valueFormatted || ''}
        />
        {valueFormatted}
      </div>
    );
  };
};
