import { Helmet } from 'react-helmet';

import { useConfigContext } from 'lib/core/config';

import usePageProps from 'lib/common/layouts/hooks/usePageProps';

export default function DynamicPageTitle() {
  const { config } = useConfigContext();
  const { title } = usePageProps();
  const { productName } = config?.BRAND || {};

  const titleSuffix = title ? ` - ${title}` : '';

  return (
    <Helmet>
      <title>
        {productName}
        {titleSuffix}
      </title>
    </Helmet>
  );
}
