import Modal from 'lib/common/components/Modal';

import { TDeleteConfirmationModalOptions } from '../CRUDTableTypes';

export const DeleteEntityModal = ({
  deleteConfirmationModalOptions,
  onClose,
  onDelete,
  deleteTarget
}: {
  deleteConfirmationModalOptions?: TDeleteConfirmationModalOptions;
  onClose: () => void;
  onDelete: () => Promise<void> | void;
  deleteTarget: string;
}) => {
  if (!deleteConfirmationModalOptions) {
    return null;
  }

  // Defaults in case the values are not provided and want to use a basic confirmation
  const { title = 'Are You Sure?', subtitle, modalContent = '' } = deleteConfirmationModalOptions;
  const open = deleteTarget !== '';

  return (
    <Modal title={title} subtitle={subtitle} open={open} onClose={onClose} onDelete={onDelete}>
      {modalContent}
    </Modal>
  );
};
