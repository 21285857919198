import { Auth } from 'aws-amplify';
import { P, match } from 'ts-pattern';

import { CognitoChallengeNames, convertToEmail } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';

import { useAgentContext } from 'lib/common/contexts/AgentContext';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import toast from 'lib/common/utils/toast';

import { AuthStages, AuthState, MfaDestinations } from '../types/AuthState';
import shouldRefreshToken from '../utils/shouldRefreshToken';
import updateRefreshTokenExpiry from '../utils/updateRefreshTokenExpiry';

export const useHandleAuthStep = ({
  setMfaDestinations,
  setInvalidCode,
  setError
}: {
  setMfaDestinations: (dest: { obfuscatedEmail: string | undefined; obfuscatedPhone: string | undefined }) => void;
  setInvalidCode: (invalid: boolean) => void;
  setError: (error: boolean) => void;
}) => {
  const { agentConfig } = useAgentContext();
  const { config } = useConfigContext();
  const tenantId = config.TENANT_ID;

  const username = convertToEmail(agentConfig?.username || '');

  // Auth step handlers will call back into handleAuthStep until user input is needed (mfa_select and mfa_entry)
  // at which point handle auth step will return and terminate, handing back control to React to set the auth stage
  // and allow the UI to collect more details. Needs work on naming of stages to make clearer when it should call back
  // into handleAuthStep, and when it should return.
  const handleAuthStep = async (state: AuthState): Promise<AuthState> => {
    try {
      return match(state)
        .returnType<Promise<AuthState> | AuthState>()
        .with({ stage: AuthStages.initial }, handleInitialChallenge(handleAuthStep))
        .with({ stage: AuthStages.insecure }, handleInsecureChallenge(handleAuthStep))
        .with({ stage: AuthStages.refresh_token }, handleRefreshToken(handleAuthStep))
        .with({ stage: AuthStages.tenant_id }, handleTenantChallenge(handleAuthStep))
        .with({ stage: AuthStages.mfa_selected }, handleMfaSelectionChallenge(handleAuthStep))
        .with({ stage: AuthStages.mfa_entered }, handleMfaCodeChallenge(handleAuthStep))
        .with({ stage: P.union(AuthStages.mfa_select, AuthStages.mfa_entry) }, (state) => state)

        .with({ stage: AuthStages.complete_auto }, (state) => state) // does not use completion handler, as we didn't get a fresh token
        .with(
          {
            stage: P.union(AuthStages.complete_mfa, AuthStages.complete_insecure, AuthStages.complete_refresh_token)
          },
          handleSigninCompletion(handleAuthStep)
        )
        .exhaustive();
    } catch (error) {
      logger.error(LogEvents.AUTH.SIGN_IN.FAIL, { error });
      setError(true);
      throw error;
    }
  };

  const handleInitialChallenge = (handleAuthStep: (state: AuthState) => Promise<AuthState>) => async () => {
    const authResponse = await Auth.signIn(username);
    const { signInUserSession } = authResponse;
    const challengeStep = authResponse.challengeParam?.challengeName;

    if (challengeStep === CognitoChallengeNames.TENANT_ID_OR_ACCESS_TOKEN && shouldRefreshToken()) {
      return handleAuthStep({ stage: AuthStages.refresh_token, authResponse });
    }

    if (signInUserSession) {
      return handleAuthStep({ stage: AuthStages.complete_auto });
    }

    const nextState = match(challengeStep)
      .with(CognitoChallengeNames.TENANT_ID_OR_ACCESS_TOKEN, () => ({ stage: AuthStages.tenant_id, authResponse }))
      .with(CognitoChallengeNames.INSECURE_SIGN_IN, () => ({ stage: AuthStages.insecure, authResponse }))
      .otherwise(() => {
        throw `Unexpected challenge step: ${challengeStep}`;
      });

    return handleAuthStep(nextState);
  };

  const handleInsecureChallenge =
    (handleAuthStep: (state: AuthState) => Promise<AuthState>) =>
    async (state: Extract<AuthState, { stage: typeof AuthStages.insecure }>) => {
      const { authResponse: lastAuthResponse } = state;
      const connectUserId = agentConfig!.routingProfile.queues.filter((q) => q.name === null)[0].queueARN.split('/')[4];
      const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, connectUserId, { tenantId });
      const challengeStep = authResponse.challengeParam?.challengeName;

      const nextState = match(challengeStep)
        .with(undefined, () => ({ stage: AuthStages.complete_insecure }))
        .otherwise(() => {
          throw `Unexpected challenge step: ${challengeStep}`;
        });

      return handleAuthStep(nextState);
    };

  const handleRefreshToken =
    (handleAuthStep: (state: AuthState) => Promise<AuthState>) =>
    async (state: Extract<AuthState, { stage: typeof AuthStages.refresh_token }>) => {
      const { authResponse: lastAuthResponse } = state;

      const authResponse = await Auth.sendCustomChallengeAnswer(
        lastAuthResponse,
        (await Auth.currentSession()).getAccessToken().getJwtToken(),
        { tenantId }
      );

      const challengeStep = authResponse.challengeParam?.challengeName;

      const nextState = match(challengeStep)
        .with(undefined, () => ({ stage: AuthStages.complete_refresh_token }))
        .otherwise(() => {
          throw `Unexpected challenge step: ${challengeStep}`;
        });

      return handleAuthStep(nextState);
    };

  const handleTenantChallenge =
    (handleAuthStep: (state: AuthState) => Promise<AuthState>) =>
    async (state: Extract<AuthState, { stage: typeof AuthStages.tenant_id }>) => {
      const { authResponse: lastAuthResponse } = state;

      const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, ' ', { tenantId });

      const challengeStep = authResponse.challengeParam?.challengeName;

      const nextState = match(challengeStep)
        .with(CognitoChallengeNames.INSECURE_SIGN_IN, () => ({ stage: AuthStages.insecure, authResponse }))
        .with(CognitoChallengeNames.CHOOSE_DESTINATION, () => {
          const { obfuscatedEmail, obfuscatedPhone } = authResponse.challengeParam ?? {};
          setMfaDestinations({ obfuscatedEmail, obfuscatedPhone });

          return match({ obfuscatedEmail, obfuscatedPhone })
            .with({ obfuscatedEmail: P.string.minLength(1), obfuscatedPhone: P.union('', P.nullish) }, () => ({
              stage: AuthStages.mfa_selected,
              authResponse,
              obfuscatedEmail,
              obfuscatedPhone,
              mfaSelection: MfaDestinations.email
            }))
            .with({ obfuscatedEmail: P.union('', P.nullish), obfuscatedPhone: P.string.minLength(1) }, () => ({
              stage: AuthStages.mfa_selected,
              authResponse,
              obfuscatedEmail,
              obfuscatedPhone,
              mfaSelection: MfaDestinations.sms
            }))
            .with({ obfuscatedEmail: P.string.minLength(1), obfuscatedPhone: P.string.minLength(1) }, () => ({
              stage: AuthStages.mfa_select,
              authResponse,
              obfuscatedEmail,
              obfuscatedPhone
            }))
            .otherwise(() => {
              throw `No MFA destinations provided`;
            });
        })
        .otherwise(() => {
          throw `Unexpected challenge step: ${challengeStep}`;
        });

      return handleAuthStep(nextState);
    };

  const handleMfaSelectionChallenge =
    (handleAuthStep: (state: AuthState) => Promise<AuthState>) =>
    async (state: Extract<AuthState, { stage: typeof AuthStages.mfa_selected }>) => {
      const { authResponse: lastAuthResponse, mfaSelection } = state;
      try {
        const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, mfaSelection, { tenantId });
        const challengeStep = authResponse.challengeParam?.challengeName;

        const nextState = match({ challengeStep, state })
          .with(
            { challengeStep: CognitoChallengeNames.OTP, state: { mfaSelection: MfaDestinations.email } },
            ({ state: { obfuscatedEmail, mfaSelection } }) => ({
              stage: AuthStages.mfa_entry,
              authResponse,
              obfuscatedEmail,
              mfaSelection
            })
          )
          .with(
            { challengeStep: CognitoChallengeNames.OTP, state: { mfaSelection: MfaDestinations.sms } },
            ({ state: { obfuscatedPhone, mfaSelection } }) => ({
              stage: AuthStages.mfa_entry,
              authResponse,
              obfuscatedPhone,
              mfaSelection
            })
          )
          .otherwise(() => {
            throw `Unexpected challenge step: ${challengeStep}`;
          });

        return handleAuthStep(nextState);
      } catch (error: any) {
        if (error.toString().includes('Max send attempts')) {
          toast('info', "Oops, you've requested too many codes. Give it a few minutes and try again.");

          const nextState = match({ state })
            .with(
              { state: { mfaSelection: MfaDestinations.email } },
              ({ state: { obfuscatedEmail, mfaSelection } }) => ({
                stage: AuthStages.mfa_entry,
                authResponse: lastAuthResponse,
                obfuscatedEmail,
                mfaSelection
              })
            )
            .with({ state: { mfaSelection: MfaDestinations.sms } }, ({ state: { obfuscatedPhone, mfaSelection } }) => ({
              stage: AuthStages.mfa_entry,
              authResponse: lastAuthResponse,
              obfuscatedPhone,
              mfaSelection
            }))
            .otherwise(() => {
              throw `Unexpected state during resend limit catch`;
            });

          return handleAuthStep(nextState);
        }

        throw error;
      }
    };

  const handleMfaCodeChallenge =
    (handleAuthStep: (state: AuthState) => Promise<AuthState>) =>
    async (state: Extract<AuthState, { stage: typeof AuthStages.mfa_entered }>) => {
      const { authResponse: lastAuthResponse, mfaSelection, mfaCode } = state;
      const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, `${mfaSelection}:${mfaCode}`, {
        tenantId
      });
      const challengeStep = authResponse.challengeParam?.challengeName;

      const nextState = match({ challengeStep, state })
        .with(
          { challengeStep: CognitoChallengeNames.OTP, state: { mfaSelection: MfaDestinations.email } },
          ({ state: { obfuscatedEmail, mfaSelection } }) => {
            setInvalidCode(true);
            return {
              stage: AuthStages.mfa_entry,
              authResponse,
              obfuscatedEmail,
              mfaSelection
            };
          }
        )
        .with(
          { challengeStep: CognitoChallengeNames.OTP, state: { mfaSelection: MfaDestinations.sms } },
          ({ state: { obfuscatedPhone, mfaSelection } }) => {
            setInvalidCode(true);
            return {
              stage: AuthStages.mfa_entry,
              authResponse,
              obfuscatedPhone,
              mfaSelection
            };
          }
        )
        .with(
          { challengeStep: undefined, state: { mfaSelection: MfaDestinations.email } },
          ({ state: { obfuscatedEmail, mfaSelection } }) => ({
            stage: AuthStages.complete_mfa,
            mfaSelection,
            obfuscatedEmail
          })
        )
        .with(
          { challengeStep: undefined, state: { mfaSelection: MfaDestinations.sms } },
          ({ state: { obfuscatedPhone, mfaSelection } }) => ({
            stage: AuthStages.complete_mfa,
            mfaSelection,
            obfuscatedPhone
          })
        )
        .otherwise(() => {
          throw `Unexpected challenge step: ${challengeStep}`;
        });

      return handleAuthStep(nextState);
    };

  const handleSigninCompletion =
    (_handleAuthStep: (state: AuthState) => Promise<AuthState>) =>
    (state: Extract<AuthState, { stage: `complete_${string}` }>) => {
      updateRefreshTokenExpiry();

      return state;
    };

  if (process.env.NODE_ENV === 'test') {
    return {
      handleAuthStep,
      handleInitialChallenge,
      handleTenantChallenge,
      handleRefreshToken,
      handleInsecureChallenge,
      handleMfaSelectionChallenge,
      handleMfaCodeChallenge,
      handleSigninCompletion
    };
  }

  return {
    handleAuthStep
  };
};
