import { faRectangleHistory } from '@fortawesome/pro-duotone-svg-icons';
import cx from 'classnames';

import useModules from 'lib/common/hooks/useModules/useModules';

import Column from 'lib/common/components/atoms/Column';

import TTask from 'lib/common/types/Task';

import CustomerData from './CustomerData';

export default function TaskModules({ selectedTask }: { selectedTask?: TTask }) {
  const { tabs, content, modules } = useModules({
    selectedTask,
    renderDefaultComponent: () => <CustomerData selectedTask={selectedTask} />,
    tabsClassName: 'mb-20',
    moduleType: 'TASK'
  });

  return (
    <Column
      minimisedIcon={faRectangleHistory}
      minWidth={250}
      isResizable
      className={cx('agent-workspace__task-modules', {
        'agent-workspace__task-modules--padding-top': !modules.length
      })}
    >
      {tabs}
      {content}
    </Column>
  );
}
