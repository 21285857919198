import { filesize } from 'filesize';

import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';

import { TAttachment } from 'lib/common/types/email/TAttachment';
import getAttachmentIcon from 'lib/common/utils/email/getAttachmentIcon';

import downloadAttachment from '../utils/downloadAttachment';

export default function AttachmentPopoverMenuItem({ attachment }: { attachment: TAttachment }) {
  return (
    <PopoverMenuItem
      key={attachment.id}
      icon={getAttachmentIcon(attachment.mimeType)}
      text={`${attachment.filename}`}
      subtitle={`(${filesize(attachment.content.byteLength, { round: 1 })})`}
      onClick={() => downloadAttachment(attachment)}
    />
  );
}
