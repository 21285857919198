import { LogEvents, logger } from 'lib/common/components/LoggerController';

import { TConfig } from 'lib/common/types/Config';
import TFetch from 'lib/common/types/Fetch';
import requestErrorToast from 'lib/common/utils/toast/requestErrorToast';

export enum EmailSendTypes {
  NEW = 'NEW',
  REPLY = 'REPLY',
  FORWARD = 'FORWARD'
}

export interface ISendEmail {
  attachmentKeys: string[];
  content: string;
  emailRuleId: string;
  subject?: string;
  to: string[];
  cc: string[];
  bcc: string[];
  type: EmailSendTypes;
  originalContactId?: string;
  originalAttachmentIds: number[];
  emailContentKey?: string;
}

export default async function sendEmail(fetch_: TFetch, config: TConfig, payload: ISendEmail) {
  try {
    await fetch_(`${config.EMAIL_URL}/user/send-email`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });

    logger.info(LogEvents.EMAIL.SENT.SUCCESS, { emailRuleId: payload.emailRuleId });
  } catch (error: any) {
    logger.error(LogEvents.EMAIL.SENT.FAIL, { emailRuleId: payload.emailRuleId, error });

    requestErrorToast({ errorReference: error.requestId });

    throw error;
  }
}
