import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { ICellRendererParams } from 'ag-grid-community';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import PopoverMenu from 'lib/common/components/PopoverMenu';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';

import { TRowActions } from '../../sharedTypes';

// Used to trick styling on clickable icon. Overridden by popover-menu on click
const voidFunction = () => undefined;

export default function ActionMenuCellRenderer<TData>(actions: TRowActions<TData>) {
  return ({ data, node: { sourceRowIndex } }: ICellRendererParams) => {
    if (!data) {
      return <></>;
    }

    const menuItems = actions.map((menuItem) => {
      if (menuItem.type === 'separator') {
        return <PopoverMenuItem.Separator />;
      }

      return (
        <PopoverMenuItem
          {...menuItem}
          key={`${sourceRowIndex}-${menuItem.text}`}
          onClick={() => {
            menuItem.onClick && menuItem.onClick(data);
          }}
        />
      );
    });

    return (
      <PopoverMenu
        anchor={
          <ClickableIcon
            id={`dotMenu-${sourceRowIndex}`}
            className={'ml-auto mr-auto'}
            icon={faEllipsis}
            onClick={voidFunction}
          />
        }
        disabled={false}
        offsetLeft={-55}
        offset={55}
        closeOnClick
        childrenDepth={1}
      >
        <>{...menuItems}</>
      </PopoverMenu>
    );
  };
}
