import { useState } from 'react';

import { ColDef } from 'ag-grid-community';

import { ScimUserStatus, TUserWithConnect } from '@cloud-wave/neon-common-lib';
import { translate } from '@cloud-wave/neon-common-lib/app';

import { BadgeCell, CRUDTable, DateCell, IconAndTextCell } from '../../common/components/Table';
import { TTableSearchProps } from '../../common/components/Table';
import { TCRUDProps, TQueryParams } from '../../common/components/Table/Implementations/CRUDTable/CRUDTableTypes';
import { StatusCell } from '../../common/components/Table/Implementations/CellRenderingHelpers/StatusCell';
import { toTitleCase } from '../../common/components/Table/utils/valueFormaters';
import { useConfigContext } from '../../core/config';
import { DeleteUserModalContent } from './components/DeleteUserModalContent';
import { roleBadgeChooser, roleIconChooser, sourceIconChooser, statusTypeChooser } from './utils/cellHelpers';

const deleteParamsInitialState = { deleteConnectUser: false };

export default function Users() {
  const { config } = useConfigContext();
  const [loading, setLoading] = useState(true);

  const deleteParamState = useState<TQueryParams>(deleteParamsInitialState);

  /*
   * TODO build column merger
   *  make a hook for getting the column defs
   * */
  const userColumnDefs: ColDef<TUserWithConnect>[] = [
    {
      headerName: 'Full Name',
      minWidth: 200,
      width: 200,
      valueGetter: ({ data }) => {
        return [data?.firstName, data?.lastName].filter(Boolean).join(' ');
      }
    },
    { field: 'email', headerName: 'Email Address', minWidth: 280, width: 280 },
    { field: 'phoneNumber', minWidth: 140, width: 140 },
    {
      field: 'role',
      valueFormatter: toTitleCase,
      cellRenderer: BadgeCell({ getBadgeType: roleBadgeChooser, getBadgeIcon: roleIconChooser }),
      width: 130,
      minWidth: 130
    },
    {
      field: 'source',
      valueFormatter: toTitleCase,
      cellRenderer: IconAndTextCell({ getIconForValue: sourceIconChooser }),
      width: 120,
      minWidth: 120
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      minWidth: 120,
      width: 120,
      type: 'dateString',
      cellRenderer: DateCell()
    },
    {
      field: 'status',
      width: 120,
      minWidth: 120,
      valueFormatter: ({ value = undefined }) => {
        if (value === ScimUserStatus.CREATED) {
          return 'Active';
        }
        return toTitleCase({ value });
      },
      cellRenderer: StatusCell({ getStatusType: statusTypeChooser })
    }
  ];

  const crudTableProps: TCRUDProps = {
    name: 'Users',
    description: `Contact ${translate('centre')} admins and agents with access to your contact ${translate('centre')}.`,
    serviceUrl: config.AGENT_SERVICE_V2_URL,
    entityParentPath: 'agent',
    entitySingular: 'user',
    entityPlural: 'users',
    entityIdField: 'user.id',
    defaultPageSize: 20,
    usesServersideActions: true,
    deleteConfirmationModalOptions: {
      subtitle: `We’ll remove this user from your contact ${translate('centre')}. They will be signed out immediately.`,
      deleteParamsInitialState,
      deleteParamState,
      modalContent: <DeleteUserModalContent deleteParamState={deleteParamState} />
    }
  };

  const searchProps: TTableSearchProps = { placeholder: 'Search users by name, email or phone number ...' };

  return (
    <CRUDTable
      columnDefs={userColumnDefs}
      CRUDProps={crudTableProps}
      searchProps={searchProps}
      initialLoadState={{ loading, setLoading }}
    />
  );
}
