import { createContext, useContext } from 'react';

type ArrayKeys<T extends readonly string[]> = T[number];

export const BROWSER_PERMISSIONS = ['microphone', 'persistent-storage', 'notifications'] as const;

export type TBrowserPermissions = ArrayKeys<typeof BROWSER_PERMISSIONS>;

export type TPermissionState = 'granted' | 'denied' | 'prompt';

export type TPermissionStates = Record<TBrowserPermissions, TPermissionState>;

type TRequirementsContext = {
  isPermissionGranted: (permission: TBrowserPermissions) => boolean;
  isAccessRequired: boolean;
  isReloadRequired: boolean;
};

const DEFAULT_REQUIREMENTS_CONTEXT: TRequirementsContext = {
  isPermissionGranted: (_) => false,
  isAccessRequired: false,
  isReloadRequired: false
};

const Context = createContext<TRequirementsContext>(DEFAULT_REQUIREMENTS_CONTEXT);

export default Context;

export const useRequirementsContext = () => useContext(Context);
