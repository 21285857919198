import { faLayerGroup } from '@fortawesome/pro-duotone-svg-icons';

import { TUserWithConnect } from '@cloud-wave/neon-common-lib';

import { usePresenceContext } from 'lib/common/contexts/PresenceContext';

import Icon from 'lib/common/components/Icon';
import { IconSizeConstraints } from 'lib/common/components/Icon/Icon';
import Text from 'lib/common/components/Text';
import UserProfile from 'lib/common/components/UserProfile';

import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';

import COLOURS from 'css/export-vars.module.scss';

import './no-tasks-placeholder.scss';

type TNoTasksPlaceholder = {
  user: TUserWithConnect;
};

function getPlaceholderText({ isBusyFromConnect, agentPresence }) {
  if (agentPresence === PRESENCE_STATES.AVAILABLE || isBusyFromConnect) {
    return 'You are ready to start receiving tasks.';
  }

  return 'Change your status to start receiving tasks.';
}

export default function NoTasksPlaceholder({ user }: TNoTasksPlaceholder) {
  const { agentPresence, isBusyFromConnect } = usePresenceContext();

  return (
    <div className="no-tasks-placeholder">
      <div className={'no-tasks-placeholder__icon'}>
        <Icon icon={faLayerGroup} color="primary" onlyConstrain={IconSizeConstraints.HEIGHT} size={25} />
      </div>
      <div className="no-tasks-placeholder__text">
        <Text type="heading2">No Active Tasks</Text>
        <Text color="darkGrey">{getPlaceholderText({ agentPresence, isBusyFromConnect })}</Text>
      </div>
      <UserProfile isLandingPage user={user} statusBackgroundColor={COLOURS.lightGrey} />
    </div>
  );
}
