import { useEffect, useRef, useState } from 'react';

import { IDatasource, IGetRowsParams, ManagedGridOptionKey } from 'ag-grid-community';
import { AgGridReactProps } from 'ag-grid-react';

import requestErrorToast from 'lib/common/utils/toast/requestErrorToast';

import { TQueryParams } from '../../CRUDTableTypes';
import { TCRUDDataSourcePropsInternal } from '../TCRUDDataSourceProps';

/**
 * returns agGrid props preset to work with the crud and base table implementations such as serverside side;
 * pagination, search, filtering and sorting
 *
 * does an initial load of data
 * to get the total number of records so we can determine the size of the skeleton loader as well as somewhat of a hack
 * to determine no data vs no search data.
 *
 * https://www.ag-grid.com/javascript-data-grid/infinite-scrolling
 *
 * Implementation based on the infinite scroll docs plus a bunch of digging as the examples seems to want people
 * not to implement their own serverside controlled table as theirs is paid, 900 per developer.
 *
 * Note searching,
 * filtering and sorting must all be implemented on the serverside endpoint for table to function properly
 *
 * */
export const useServersideControlledDataSource = ({
  CRUDProps: { entityPlural, defaultPageSize = 20 },
  initialLoadState,
  dataModificationProps: { search },
  crudClient
}: TCRUDDataSourcePropsInternal) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [actualPageSize, setActualPageSize] = useState(defaultPageSize);
  const setGridOptionRef = useRef<(value: ManagedGridOptionKey, data: any) => void>((any: any) => {});
  const showNoRowOverlayRef = useRef<() => void>(() => {});
  const hideOverlayRef = useRef<() => void>(() => {});

  const fetchFailed = ({ message, requestId }: { message: string; requestId?: string }) => {
    requestErrorToast({ errorMessage: message, errorReference: requestId });
  };

  /*
   * TODO when getting react query working for this
   * use infinite query pagination to have a set of data available
   * */

  const loadInitialPageForRenderInfo = async () => {
    const { count } = await crudClient.list({ queryParams: { page: 1, pageSize: 1, search } });

    setTotalRecords(count);
    if (initialLoadState && initialLoadState.loading) {
      initialLoadState.setLoading(false);
    }
  };

  useEffect(() => {
    loadInitialPageForRenderInfo();
  }, []);

  const deleteEntity = (targetEntityId: string, queryParams?: TQueryParams) => {
    try {
      crudClient.delete({ queryParams, id: targetEntityId });
    } catch (error: unknown) {
      if (error instanceof Error) {
        fetchFailed(error);
      }
    }
  };

  const datasource: IDatasource = {
    rowCount: undefined,
    getRows: async ({ startRow, successCallback, filterModel, sortModel, failCallback }: IGetRowsParams) => {
      console.log({ filterModel });
      try {
        const pageNeeded = Math.ceil((startRow + 1) / actualPageSize);
        const { serverEntities, count } = await crudClient.list({
          queryParams: { page: pageNeeded, pageSize: actualPageSize, search }
        });

        if (count < 1) {
          showNoRowOverlayRef.current();
        }

        successCallback(serverEntities, count);
      } catch (error) {
        failCallback();

        if (error instanceof Error) {
          fetchFailed(error);
        }
      }
    }
  };

  useEffect(() => {
    setGridOptionRef.current('datasource', datasource);
  }, [search]);

  const infiniteInitialRowCount = totalRecords > defaultPageSize ? defaultPageSize : totalRecords;

  const agDataSourceProps: AgGridReactProps = {
    rowModelType: 'infinite',
    cacheBlockSize: actualPageSize,
    cacheOverflowSize: 0,
    maxConcurrentDatasourceRequests: 2,
    infiniteInitialRowCount,
    paginationPageSize: actualPageSize,
    paginationPageSizeSelector: false, // temp disabled till reloading works nicer
    onGridReady: ({ api: { setGridOption, showNoRowsOverlay, hideOverlay } }) => {
      setGridOption('datasource', datasource);
      setGridOptionRef.current = (event: ManagedGridOptionKey, param: any) => setGridOption(event as any, param);
      showNoRowOverlayRef.current = showNoRowsOverlay;
      hideOverlayRef.current = hideOverlay;
    },
    onPaginationChanged: ({}) => {
      // TODO/STRETCH when getting react query working for this
      // update page size settings
      // invalidate query data
      // force a re render
    }
  };

  const agDataSourcePropsEmptyState: AgGridReactProps = {
    rowData: []
  };

  return {
    // TODO search filter https://www.ag-grid.com/javascript-data-grid/filter-api/#example-get--set-all-filter-models
    agDataSourceProps: totalRecords > 0 && !initialLoadState?.loading ? agDataSourceProps : agDataSourcePropsEmptyState,
    deleteEntity
  };
};
