import transformLogs from './utils/transformLogs';

// https://cloud-wave.atlassian.net/wiki/spaces/PROD/pages/1603043329/Logs

// Needed to somehow get a unique type that is technically a string but cannot be replicated by using a string directly.
// Basically forcing us to use the LogEvent object to pass messages instead of being able to pass string directly to the function.

type Distinct<T, DistinctName> = T & { __TYPE__: DistinctName };

export type TLogEventValue = Distinct<string, 'TLogEventValue'>;

const LogEvent = '' as TLogEventValue;

const ModalActions = () => ({
  OPEN: LogEvent,
  CLOSE: LogEvent,
  SUCCESS: LogEvent,
  FAIL: LogEvent
});

const SuccessFail = () => ({
  SUCCESS: LogEvent,
  FAIL: LogEvent
});

const CUD = () => ({
  CREATE: SuccessFail(),
  UPDATE: SuccessFail(),
  DELETE: SuccessFail()
});

const LogEventsMap = {
  AGENT_TASK: {
    CREATE: ModalActions(),
    FETCH_AGENTS: SuccessFail()
  },
  OUTBOUND_SMS_SEND: ModalActions(),

  CONNECT_SOFTPHONE_ERROR: LogEvent,

  TASK: {
    INCOMING: LogEvent,
    OUTBOUND: LogEvent,
    MISSED: LogEvent,
    ACCEPT: SuccessFail(),
    TRANSFER_TO_QUEUE: SuccessFail(),
    CONNECT: SuccessFail(),
    REJECT: SuccessFail(),
    CLEAR: SuccessFail(),
    DESTROY: SuccessFail(),
    CLOSED: SuccessFail()
  },

  CALL_RECORDING: {
    PAUSE: SuccessFail(),
    RESUME: SuccessFail(),
    STOP: SuccessFail()
  },

  CALL_ACTIONS: {
    HOLD: SuccessFail(),
    RESUME: SuccessFail(),
    MUTE: SuccessFail(),
    UNMUTE: SuccessFail()
  },

  CONFERENCE_ACTIONS: {
    HOLD: SuccessFail(),
    RESUME: SuccessFail(),
    END: SuccessFail()
  },

  OUTBOUND_CALL: {
    QUEUED: LogEvent,
    INITIATED: SuccessFail()
  },

  AFTER_CALL_WORK: {
    SKIPPED: LogEvent,
    SUBMITTED: SuccessFail()
  },

  INTERACTION: {
    CREATE: SuccessFail()
  },

  EMAIL: {
    DRAFT_CREATED: ModalActions(),
    REPLY_ALL_CREATED: LogEvent,
    REPLY_CREATED: LogEvent,
    FORWARD_CREATED: LogEvent,
    SENT: SuccessFail(),
    CONTENT_LOAD_FAILED: LogEvent,
    IMAGE_RESIZE_FAILED: LogEvent
  },

  AGENT_STATUS_CHANGED: {
    BY_CONNECT: LogEvent,
    MANUALLY: SuccessFail()
  },

  STREAMS_INIT: SuccessFail(),

  AUTH: {
    SIGN_IN: SuccessFail(),
    SIGN_OUT: {
      INITIATED: LogEvent,
      COMPLETED: SuccessFail()
    },
    FORGET_DEVICE: SuccessFail(),
    AGENT_CONFIG: SuccessFail(),
    INITIALIZE_APP: SuccessFail(),
    INITIALIZE_USER: SuccessFail(),
    REFRESH_TOKEN_FAIL: LogEvent,
    SESSION_EXPIRED: LogEvent
  },

  PAGE_RELOAD_WITH_TASKS: LogEvent,

  CUSTOMER_PROFILE: CUD(),

  CONTACT: {
    PERSONAL: CUD(),
    COMPANY: CUD(),
    LOAD: SuccessFail(),
    REFRESH: SuccessFail()
  },

  WIDGET_LOAD_FAILED: LogEvent,
  REPORT_LOAD_FAILED: LogEvent,
  QUEUES_LOAD_FAILED: LogEvent,
  INTERACTION_HISTORY_LOAD_FAILED: LogEvent,
  MEDIA_DEVICES_LOAD_FAILED: LogEvent,
  EXTERNAL_SCRIPT_LOAD_FAILED: LogEvent,
  ATTACHMENT_UPLOAD_FAILED: LogEvent,

  PERFORMANCE: {
    HEARTBEAT: SuccessFail(),
    ACK_TIMEOUT: LogEvent
  },

  TASK_EVENT: {
    DROPPED: LogEvent,
    PROCESSED: LogEvent
  },

  AGENT_MANAGEMENT: CUD(),

  DEFAULT: LogEvent
};

export type TLogEvents = typeof LogEventsMap;

const LogEvents: TLogEvents = transformLogs(LogEventsMap);

export default LogEvents;
