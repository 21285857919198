import { faAddressBook, faHeadset, faShieldKeyhole, faUserPen } from '@fortawesome/pro-regular-svg-icons';
import { match } from 'ts-pattern';

import { UserSources, UserTypes } from '@cloud-wave/neon-common-lib';

import { BadgeTypes } from 'lib/common/components/Badge/Badge';
import { StatusTypes } from 'lib/common/components/Status/Status';

export const roleIconChooser = (value?: string | null) => (value === UserTypes.ADMIN ? faShieldKeyhole : faHeadset);
export const sourceIconChooser = (value?: string | null) =>
  value === UserSources.EXTERNAL ? faAddressBook : faUserPen;

export const statusTypeChooser = (value?: string | null) =>
  match(value)
    .with('ERROR', () => StatusTypes.DANGER)
    .with('ACTIVE', () => StatusTypes.SUCCESS)
    .with('CREATED', () => StatusTypes.SUCCESS)
    .otherwise(() => StatusTypes.NEUTRAL);

export const roleBadgeChooser = (value?: string | null) =>
  match(value)
    .with(UserTypes.ADMIN, () => BadgeTypes.BLACK)
    .with(UserTypes.AGENT, () => BadgeTypes.NEUTRAL)
    .otherwise(() => BadgeTypes.NEUTRAL);
