import { ContactType, TUserWithConnect } from '@cloud-wave/neon-common-lib';

import { TContactListData } from '../../../hooks/usePaginatedLoad';

export const formatName = (...nameParts: string[]) =>
  nameParts
    ? nameParts
        ?.map((part) => part?.trim())
        .join(' ')
        .trim()
    : 'Unnamed Contact';

export function transformUser(user: TUserWithConnect): TContactListData {
  return {
    contactId: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    type: ContactType.AGENT,
    availability: user.availability,
    phoneNumber: user.phoneNumber,
    email: user.email,
    originalContact: user,
    connectUserId: user.connectInformation.connectUserId
  };
}
