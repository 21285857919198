import ReactSelect from 'lib/common/components/ReactSelect';
import SegmentedControls from 'lib/common/components/SegmentedControls';

import AgentDropdown from '../../AgentDropdown';
import { Tab } from '../useCreateAgentTask';

import '../styles/create-agent-task.scss';

export default function TaskLocation({
  setSelectedTab,
  selectedEntity,
  selectedTab,
  queueOptionsList,
  setSelectedEntity
}) {
  const props = {
    id: 'create-task-assign-select',
    classNamePrefix: 'select',
    value: selectedEntity || '',
    placeholder: `Select a${selectedTab === Tab.QUEUE ? ' queue' : 'n agent'}`,
    options: selectedTab === Tab.QUEUE ? queueOptionsList : void 0,
    onChange: (newValue) => setSelectedEntity(newValue)
  };

  return (
    <>
      <div className="create-agent-task__tabs">
        <SegmentedControls
          ariaLabel="Task location"
          options={Object.values(Tab).map((tab) => ({
            label: tab,
            value: tab
          }))}
          onSelect={setSelectedTab}
          initialIndices={0}
        />
      </div>
      <div role="tabpanel">{selectedTab === Tab.AGENT ? <AgentDropdown {...props} /> : <ReactSelect {...props} />}</div>
    </>
  );
}
