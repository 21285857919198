import { ReactNode, RefObject } from 'react';

import cx from 'classnames';

import { TUserWithConnect } from '@cloud-wave/neon-common-lib';


import WarningBanner from 'lib/common/components/WarningBanner';

import NotificationBanner from './components/NotificationBanner';
import SoftphoneHeader from './components/SoftphoneHeader';
import SoftphoneSidebar from './components/SoftphoneSidebar';

import './softphone-layout.scss';

interface ISoftphoneLayoutProps {
  children: ReactNode;
  user: TUserWithConnect;
  isReversedLayout: boolean;
  contentRef: RefObject<HTMLDivElement>;
}

export default function SoftphoneLayout({ children, user, isReversedLayout, contentRef }: ISoftphoneLayoutProps) {
  return (
    <div className="softphone-layout">
      <SoftphoneHeader isReversedLayout={isReversedLayout} />
      <div className={cx('softphone-layout__content', { 'softphone-layout__content--reversed': isReversedLayout })}>
        <SoftphoneSidebar user={user} isReversedLayout={isReversedLayout} />
        <main className="softphone-layout__container" ref={contentRef}>
          <WarningBanner className="softphone-layout__warning-banner" />
          <NotificationBanner className="softphone-layout__notification-banner" />
          <div className="softphone-layout__container__children">{children}</div>
        </main>
      </div>
    </div>
  );
}
