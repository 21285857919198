import { FC } from 'react';
import { ClearIndicatorProps, DropdownIndicatorProps, LoadingIndicatorProps, components } from 'react-select';

import { faChevronDown, faCircleNotch, faTimes } from '@fortawesome/pro-regular-svg-icons';

import ClickableIcon from '../ClickableIcon';
import Icon from '../Icon';

export const DropdownIndicator: FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon={faChevronDown} size={13} color="darkGrey" className="ml-5 mr-5" />
    </components.DropdownIndicator>
  );
};

export const ClearIndicator: FC<ClearIndicatorProps> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <ClickableIcon icon={faTimes} size={15} />
    </components.ClearIndicator>
  );
};

export const LoadingIndicator: FC<LoadingIndicatorProps> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon icon={faCircleNotch} spin size={13} color="darkGrey" />
    </components.ClearIndicator>
  );
};
