import { TModal } from 'lib/common/components/Modal/Modal';

import TEmailTask from 'lib/common/types/EmailTask';

import { EmailModalTypes } from './EmailModal';

const getModalProps = (propOverrides: Partial<TModal>, task: TEmailTask) => ({
  [EmailModalTypes.ORIGINAL_EMAIL]: { title: 'View Original Email', large: true },
  [EmailModalTypes.WARNING]: {
    title: 'Are You Sure You Want To Send?',
    primaryButtonText: 'Send',
    secondaryButtonText: 'Cancel',
    hideSecondaryButton: false,
    syncAction: false,
    ...propOverrides
  },
  [EmailModalTypes.CANT_SEND]: {
    title: "This Email Can't Be Sent"
  },
  [EmailModalTypes.ATTACHED_DATA]: { title: 'Attached Data' },
  [EmailModalTypes.WRAP_UP_CODES_AND_NOTES]: {
    title: 'Wrap Up Code / Notes',
    subtitle: 'You’ll still be able to select a wrap code and fill out notes after the interaction.'
  },
  [EmailModalTypes.CUSTOMER_PROFILE]: {
    title: task.email?.originalEmail?.emailAddress
  }
});

export { getModalProps };
