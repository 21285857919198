import { ReactNode } from 'react';

import { IconDefinition, faClose } from '@fortawesome/pro-regular-svg-icons';
import { Backdrop, Modal as MUIModal, Slide } from '@material-ui/core';
import cx from 'classnames';

import { Permissions } from '@cloud-wave/neon-common-lib';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import Button from 'lib/common/components/Button';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import Text from 'lib/common/components/Text';

import styles from './softphone-modal.module.scss';

const SOFTPHONE_MODAL_BACKDROP_TIMEOUT = 500;

interface ISoftphoneModalProps {
  children: ReactNode;
  isModalOpen: boolean;
  closeModal: () => void;
  handleClearContent: () => void;
  onSave?: () => void;
  onDelete?: () => void;
  onSecondary?: () => void;
  saveDisabled?: boolean;
  secondaryDisabled?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  secondaryAsyncAction?: boolean;
  deleteButtonText?: string;
  secondaryButtonIcon?: IconDefinition;
  isReversedLayout: boolean;
  onSuccess?: () => void;
  title?: string;
  subtitle?: string;
  onCancel?: () => void;
  hideSecondaryButton?: boolean;
  syncAction?: boolean;
}

const LEFT_DIRECTION = 'left';
const RIGHT_DIRECTION = 'right';
const MODAL_CONTENT_TIMEOUT = 300;

export default function ({
  children,
  isModalOpen,
  closeModal,
  handleClearContent,
  onSave,
  onDelete,
  onSecondary,
  saveDisabled,
  secondaryDisabled,
  primaryButtonText,
  secondaryButtonText,
  secondaryAsyncAction,
  deleteButtonText,
  secondaryButtonIcon,
  isReversedLayout,
  onSuccess,
  title,
  subtitle,
  onCancel,
  hideSecondaryButton,
  syncAction
}: ISoftphoneModalProps) {
  const { hasPermission } = usePermissionsContext();

  const showFooter = Boolean(onSave || onDelete || onCancel || onSecondary);
  const isForcedSoftphone = hasPermission({ permission: Permissions.SOFTPHONE_ONLY });

  return (
    <MUIModal
      open={Boolean(isModalOpen)}
      className={cx(styles['softphone-modal'], {
        [styles['softphone-modal--reversed']]: isReversedLayout,
        [styles['softphone-modal--forced']]: isForcedSoftphone
      })}
      onClose={closeModal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: SOFTPHONE_MODAL_BACKDROP_TIMEOUT
      }}
    >
      <Slide
        direction={isReversedLayout ? RIGHT_DIRECTION : LEFT_DIRECTION}
        in={Boolean(isModalOpen)}
        timeout={MODAL_CONTENT_TIMEOUT}
        onExited={handleClearContent}
      >
        <div className={styles['softphone-modal__content']}>
          <header>
            {title && (
              <div className={styles['softphone-modal__title']}>
                <Text htmlElement="h1" color="darkBlue" type="heading2">
                  {title}
                </Text>
                <ClickableIcon
                  aria-label="Close modal"
                  className={styles['softphone-modal__close-button']}
                  onClick={closeModal}
                  icon={faClose}
                />
              </div>
            )}
            {subtitle && (
              <div className={styles['softphone-modal__subtitle']}>
                <Text type="label">{subtitle}</Text>
              </div>
            )}
          </header>
          <main className={styles['softphone-modal__content__children']}>{children}</main>
          {showFooter && (
            <section aria-label="footer" className={styles['softphone-modal__content__footer']}>
              {!hideSecondaryButton && (
                <Button
                  className={styles['softphone-modal__content__footer__button']}
                  onClick={onSecondary || onCancel || closeModal}
                  styleType="SECONDARY"
                  icon={secondaryButtonIcon}
                  asyncAction={secondaryAsyncAction}
                  disabled={secondaryDisabled}
                >
                  {secondaryButtonText || 'Cancel'}
                </Button>
              )}
              {onSave && (
                <Button
                  data-testid="modal-save-button"
                  disabled={saveDisabled}
                  onClick={saveDisabled ? void 0 : onSave}
                  onSuccess={onSuccess || closeModal}
                  asyncAction
                >
                  {primaryButtonText || 'Save'}
                </Button>
              )}
              {onDelete && (
                <Button
                  data-testid="modal-delete-button"
                  styleType="DANGER"
                  onClick={onDelete}
                  onSuccess={onSuccess || closeModal}
                  asyncAction={!syncAction}
                >
                  {deleteButtonText || 'Delete'}
                </Button>
              )}
            </section>
          )}
        </div>
      </Slide>
    </MUIModal>
  );
}
