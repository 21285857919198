import { LogEvents, logger } from 'lib/common/components/LoggerController';

import { TConfig } from 'lib/common/types/Config';
import toast from 'lib/common/utils/toast';

export default async function handleConnectSignOut({ config }: { config: TConfig }) {
  try {
    await fetch(`${config.CONNECT_HOST}/connect/logout`, {
      credentials: 'include',
      mode: 'no-cors'
    });
  } catch (error) {
    toast('error', "Oops, sign out didn't work as expected. Please try again or reload the page.");
    logger.error(LogEvents.AUTH.SIGN_OUT.COMPLETED.FAIL, { error });
    return;
  }

  connect.core.terminate();
}
