import { faColumns3, faPrint } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import Icon from 'lib/common/components/Icon';

import { TTableActions, TTableToolbarProps } from '../../sharedTypes';
import { TableSearch } from './TableSearch';

import styles from '../basic-table.module.scss';

const TableToolBarActions = ({ tableActions }: { tableActions?: TTableActions }) => {
  if (!tableActions) {
    return null;
  }

  const { columnChooser, print } = tableActions;

  return (
    <>
      {print && <Icon onClick={print} icon={faPrint} />}
      {columnChooser && <Icon onClick={columnChooser} icon={faColumns3} />}
    </>
  );
};

export const TableToolbar = ({ searchProps, tableActions }: TTableToolbarProps) => {
  if (!searchProps && !tableActions) {
    return null;
  }

  return (
    <div className={cx(styles['basic-table__toolbar'])}>
      <div className={cx(styles['basic-table__search-container'])}>
        {searchProps && <TableSearch {...searchProps} />}
      </div>
      <div className={cx(styles['basic-table__toolbar__actions'])}>
        <TableToolBarActions tableActions={tableActions} />
      </div>
    </div>
  );
};
