export default {
  au: 'AU',
  aus: 'AU',
  ore: 'US',
  nvg: 'US',
  lon: 'GB',
  saf: 'ZA',
  'agero-nvg': 'US',
  'agero-ore': 'US'
};
